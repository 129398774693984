import * as api from '.'
import { Collections, getCurrentUser } from './firebase'
import { Org, OrgMember, OrgRoles } from './models'
import config from '../config/app-config.json'
import { User } from 'auth/auth-model'

const env = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV
const API_URL = `${config[env].backend.base}/invitations/${config[env].backend.endpoints.invitations.version}${config[env].backend.endpoints.invitations.paths.ORGS}`
// config[env].functions || 'http://localhost:5001/dev-siftora/us-central1/siftora'


export const getUserOrgs = (userID:string, changeHandler) => {
  return api.subscribe(Collections.ORG_MEMBERS).where('user.id', '==', userID)
  .onData(records => {
    return changeHandler(records as Org[])
  })
}

export const getOrg = async (orgID:string) => {
  const resp = await api.get(Collections.ORGS, orgID)
  return resp as Org
}


// Org Membership
interface A {
  (member:OrgMember, role:OrgRoles)
}

interface M {
  (member:OrgMember): void
}

export const inviteOrgMembers = async (org_id:string, members: User[]) => {
  const response = {error: false, message: ''}
  try {
    const currentUser = getCurrentUser()
    const idToken = await currentUser?.getIdToken()
    
    await fetch(`${API_URL}/${org_id}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'no-cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Accept': ''
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({members, token: idToken}),
    })
  } catch(e) {
    response.error = true
    response.message = e?.message
    console.error(e)
  }
  return response
}

export const addMemberRole:A = (member, role) => {
  member.roles.push(role)
  return api.update(Collections.ORG_MEMBERS, member.id, member)
}

export const removeMemberRole:A = (member, role ) => {
  const roleIndex = member.roles.findIndex(userRole => userRole === role)
  member.roles.splice(roleIndex, 1)
  return api.update(Collections.ORG_MEMBERS, member.id, member)
}

export const toggleMembershipStatus:M = ( member ) => {
  return api.update(Collections.ORG_MEMBERS, member.id, {
    ...member,
    disabled: !member.disabled
  })
}

export const updateOrgMemberRoles = (newRole:OrgRoles, member:OrgMember) => {
  if (member.roles.includes(OrgRoles.GUEST)) {
    if (newRole === OrgRoles.GUEST) {
      return removeMemberRole(member, newRole)
    } else {
      return Promise.reject('Guests can not have other roles')
    }
  }
  if (newRole === OrgRoles.GUEST) { 
    member.roles.length = 0
    return addMemberRole(member, newRole)
  }

  if (member.roles.includes(newRole)) {
    return removeMemberRole(member, newRole)
  }
  
  return addMemberRole(member, newRole)
}

export const getOrgMembers = (org_id, handler) => {
  return api.subscribe(Collections.ORG_MEMBERS).where('org.id', '==', org_id as string).onData((data) => {
    handler(data as OrgMember[])
  })
}