import * as React from 'react'
import { LearningTrackSelect } from 'LearningTracks/LearningTrackSelect'
import { LearningTrackProps } from 'LearningTracks/model'
import { getLearningTrackMembers } from 'api/learning-tracks'
import { LearningTrackMember, MentorMentee } from 'api/models'
import { MenteeList } from './MenteesList'
import { LearnersList } from './LearnersList'
import { getMenteeByLearningTrack } from 'api/mentorship'


interface R {
  mentors: LearningTrackMember[]
  learners: LearningTrackMember[]
}

export const Mentorship = () => {

  const [selectedTrack, setSelectedTrack] = React.useState({} as LearningTrackProps)
  const [members, setMembers] = React.useState<LearningTrackMember[]>([])
  const [mentors, setMentors] = React.useState<LearningTrackMember[]>([])
  const [learners, setLearners] = React.useState<LearningTrackMember[]>([])
  const [mentees, setMentees] = React.useState<MentorMentee[]>([])

  React.useEffect(() => {
    if (!selectedTrack.id) return
    getLearningTrackMembers(selectedTrack.id as string, setMembers)
  }, [selectedTrack.id])

  React.useEffect(() => {
    if (!selectedTrack.id) return
    getMenteeByLearningTrack(selectedTrack.id, setMentees)
  }, [selectedTrack.id])

  React.useEffect(() => {
    const reducerOutput:R = {mentors: [], learners: []}
    const { mentors, learners }:R = members.reduce((output, member) => {
      switch(member.role) {
        case 'Mentor':
          output.mentors.push(member)
          break
        default:
          output.learners.push(member)
      }

      return output
    }, reducerOutput)
    setMentors(mentors)
    setLearners(learners)
  }, [members])

  return <div className=''>
    <div className='border-b border-gray-100 grid grid-cols-2 bg-white gap-2 mb-2'>
      <div><LearningTrackSelect onTrackChange={setSelectedTrack} /></div>
      <div></div>
    </div>
    <div className='border-b border-gray-100 grid grid-cols-2 auto-cols-auto gap-2 text-sm'>
      <div>
        <MenteeList mentors={mentors} />
      </div>
      <div><LearnersList learners={learners} mentors={mentors} track={selectedTrack} mentees={mentees} /></div>
    </div>
  </div>
}