import firebase from 'firebase'
import { Collections, Storage } from './firebase'
import { Meta } from './models'
import * as api from '.'

type ProgressCallback = (progress:number) => void
type ErrorCallback = (err: Error) => void
type SuccessCallback = (fileUrl: string) => void

interface uploadReturnProps {
  onProgress: (callback:ProgressCallback) => void
  onError: ErrorCallback
  onComplete: SuccessCallback
}

interface S {
  (
    file: File,
    pathname: string,
    onError: ErrorCallback,
    onComplete: SuccessCallback
  ):void
}

export const saveFile:S = (file, pathname, onError, onComplete) => {
  const OrgFolder = Storage.child(pathname)

  OrgFolder.put(file)
    .on(firebase.storage.TaskEvent.STATE_CHANGED, 
      (snapshot) => {

      },
      (error) => {
        onError(error)
      },
      async () => {
        const url = await OrgFolder.getDownloadURL()
        onComplete(url)
      }
    )
}
interface M {
  (meta:Omit<Meta, 'id'>): void
}
export const saveMediaMeta:M = (meta) => {
  return api.save(Collections.MEDIA, meta)
}

export const getMediaMeta = (media_id) => {

}

export const fetchMediaMeta = (org_id, callBack) => {
  api.subscribe(Collections.MEDIA).onData((records) => {
    callBack(records as Partial<Meta>)
  })
} 