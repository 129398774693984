import { TrainingSessionsProps } from 'api/models'
import { format } from 'date-fns'
import * as React from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

interface T {
  trainingSessions: TrainingSessionsProps[]
}
export const TrainSessionList: React.FC<T> = ({ trainingSessions }) => {
  const { org_id, training_id } = useParams<{ org_id:string, training_id:string }>()
  let rows
  if (!trainingSessions.length) {
    rows = <tr>
      <td colSpan={3} align='center'>No Sessions registered</td>
    </tr>
  } else {
    rows = trainingSessions.map(session => {
      return <tr className='border-b border-gray-100' key={session.id}>
        <td className='p-2'>
          <Link to={`/orgs/${org_id}/trainings/${training_id}/sessions/${session.id}`} className='text-secondary-700'>
            {session.name}
          </Link>
        </td>
        <td className='p-2'>{format(session.start_date, 'dd MMM, yyyy')}</td>
        <td className='p-2'>{`${session.duration} ${session.duration_type}`}</td>
      </tr>
    })
  }

  return <div className='bg-white'>
    <div className='p-2 border-b-2 border-gray-100'>Training Data</div>
    <table className="min-w-full divide-y divide-gray-100 bg-white mt-4">
      <thead className='bg-gray-50'>
        <tr className='bg-gray-50'>
          <th className='p-2' align='left'>Session</th>
          <th className='p-2' align='left'>Start Date</th>
          <th className='p-2' align='left'>Duration</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  </div>
}