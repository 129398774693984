import { Button } from 'components/Buttons'
import { FormField } from 'components/InputText'
import { ModalBody } from 'components/Modal/ModalBody'
import { ModalFooter } from 'components/Modal/ModalFooter'
import { ModalHeader } from 'components/Modal/ModalHeader'
import { useFormik } from 'formik'
import * as React from 'react'

export interface D {
  name:string 
  description:string
}
interface T {
  initialValues: D
  validate(values:D): { name: boolean, description: boolean}
  onSubmit(values): void
}

export const  LearningTrackForm:React.FC<T> = ({
  initialValues,
  onSubmit,
  validate
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })

  //Todo: This should use formik.isValid but it was returninng false no matter what
  const ok = (formik.dirty && !(formik.errors.name || formik.errors.description))
  return <div>
    <form onSubmit={formik.handleSubmit}>
      <ModalHeader title='New Learning Track' />
      <ModalBody>
        <FormField
          name='name'
          hasError={formik.errors.name} 
          label='Name of Learning Track'
          onChange={formik.handleChange}
        />
        <FormField
          name='description'
          hasError={formik.errors.description} 
          label='Description'
          onChange={formik.handleChange}
        />
      </ModalBody>
      <ModalFooter>
        <Button type='button' color='primary' raised disabled={!ok} onClick={() => onSubmit(formik.values)}>
          Submit
        </Button>
      </ModalFooter>
    </form>
  </div>
}