import * as React from 'react'

interface DropDownItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  className?: string
  onClick?: () => void
}

export const DropDownItem: React.FC<DropDownItemProps> = ({ children, disabled, onClick }) => {
  const styles = disabled ? 'pointer-events-auto bg-gray-100 text-gray-400' : 'text-gray-600 bg-white'
  return <div
    onClick={onClick}
    className={`
      ${styles} p-2 border-t border-gray-100 
      bottom-0 w-full hover:bg-gray-100 flex gap-2 items-center
      cursor-pointer
    `}>
    {children}
  </div>
}

interface DropDownProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: 'normal' | 'full'
  isOpen: boolean,
  children: React.ReactNode | React.ReactNode[] 
}

export const DropDown: React.FC<DropDownProps> = ({
  children,
  width = 'normal',
  onMouseLeave,
  isOpen = false
}) => {
  const [showDropDown, toggleShowDropDown] = React.useState('hidden')

  React.useEffect(() => {
    toggleShowDropDown(!isOpen ? 'hidden' : '')
  }, [isOpen])

  return <div className='relative' onMouseLeave={onMouseLeave}>
    <div className={
      `${showDropDown} 
          z-10 shadow-lg absolute w-max top-0 left-0
      `}>
      {children}
    </div>
  </div>
}