import * as React from 'react'
import { ReactComponent as IconClose } from 'assets/Icons/close.svg'
import { OutlineButton } from 'components/Buttons'
import { ModalContext } from '.'

interface P {
  title: string // Title of the modal
}

export const ModalHeader: React.FC<P> = ({ title }) => {
  const { closeModal } = React.useContext(ModalContext)
  return <div className='flex justify-between bg-white rounded-t-md p-2  border-b border-gray-100 items-center'>
    <span>{title}</span>
    <OutlineButton onClick={closeModal}><IconClose height='20' /></OutlineButton>
  </div>
}