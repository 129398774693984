
import { fetchLearningTracks } from 'api/learning-tracks'
import { saveTraining } from 'api/trainings'
import { SelectOption } from 'components/Select'
import * as React from 'react'
import { useParams } from 'react-router'
import { LearningTrackProps } from 'LearningTracks/model'
import { Org } from 'api/models'
import { NewTrainingForm, TrainingFormProps } from './NewTrainingForm'
import { getOrg } from 'api/orgs'

export const NewTraining = ({ afterSubmit }) => {

  const initialValues:TrainingFormProps = {
    title: '',
    description: ''
  }
  const { org_id } = useParams<{org_id:string}>()

  const [org, setOrg] = React.useState<Org>({} as Org)
  const [learningTracks, setLearningTracks] = React.useState<SelectOption[]>([] as SelectOption[])
  const [selectedLearningTrack, setSelectedLearningTrack] = React.useState({} as SelectOption)
  
  React.useEffect(() => {
    getOrg(org_id)
      .then(org => {
        if (org) setOrg(org)
      })
      .catch(err => console.error(err))
    
    fetchLearningTracks(org_id, (learningTracks:LearningTrackProps[]) => {
      const tracks = learningTracks.map(track => {
        return {
          value: track.id as string, 
          label: track.name
        }
      })
      setLearningTracks(tracks)
    })
  }, [org_id])

  const handleSelectedLearningTrackChange = React.useCallback((id:string) => {
    const selectedLearningTrack = learningTracks.find(track => {
      return (id === track.value)
    })
    if (selectedLearningTrack) {
      setSelectedLearningTrack(selectedLearningTrack)
    }
    
  }, [learningTracks])

  const handleSubmit = React.useCallback((values:TrainingFormProps) => {
    saveTraining({
      id: '',
      ...values,
      org: {id: org_id, name: org.name},
      learning_track: {
        id: selectedLearningTrack.value, 
        name: selectedLearningTrack.label
      }
    })
      .then(afterSubmit)
      .catch(err => console.error(err))
  }, [afterSubmit, org, org_id, selectedLearningTrack])

  const validate = React.useCallback((values:TrainingFormProps) => {
    const errors = {title: false, description: false}
    if (values.title.trim().length < 6 ){
      errors.title = true
    }
    if (values.description.trim().length < 10) {
      errors.description = true
    }
    return errors
  }, [])

  return <NewTrainingForm 
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validate={validate}
    selectedLearningTrack={selectedLearningTrack}
    learningTracks={learningTracks}
    onSelectedLearningTrackChange={handleSelectedLearningTrackChange}
  />
}