import { getMenteeByMentorId } from 'api/mentorship'
import { LearningTrackMember, MentorMentee } from 'api/models'
import { Avatar } from 'components/Avater'
import { MentorsSelect } from 'Mentorship/MentorsSelect'
import * as React from 'react'
import { Link, useParams } from 'react-router-dom'

interface T {
  mentors: LearningTrackMember[]
}
export const MenteeList:React.FC<T> = ({ mentors }) => {
  const [mentor, setMentor] = React.useState<LearningTrackMember | null> ()
  const [mentees, setMentees] = React.useState<MentorMentee[]>([])

  const { org_id } = useParams<{org_id: string}>()

  React.useEffect(() => {
    if (!mentor) return
    const controller = new AbortController()

    getMenteeByMentorId(mentor?.id, setMentees)
    return () => controller.abort()
  }, [mentor])

  React.useEffect(() => { 
    if (!mentors.length) return
    setMentor(mentors[0])
  }, [mentors])

  const rows = mentees.map(({ id, mentee }) => {
    return <Link className='grid grid-cols-4 gap-2 border-b border-gray-100 p-2 items-center' key={id} to={`/orgs/${org_id}/mentorship/${id}`}>
      <Avatar user={mentee} />
      <div className='col-span-3'>{mentee.name}</div>
    </Link>
  })
  return <div className='bg-white'>
    <div className='border-b-2 border-gray-100 p-2  flex gap-2 items-center justify-between'>
      <div>Mentees</div>
      <MentorsSelect mentors={mentors} selectedMentor={mentor} onSelectedMentor={setMentor} />
    </div>
    {rows.length ? rows : <div className=' p-2'>No mentees assigned</div>}
  </div>
}