import { Colors } from 'helpers/colors'
import * as React from 'react'
import { Editor, EditorState, RichUtils } from 'draft-js'
import cn from 'classnames'
import { SlideBuilderToolBox } from 'components/Slide/SlideTools'
import 'draft-js/dist/Draft.css'

interface TextSlideProp {
  text: string,
  backgroundColor: Colors,
  textColor: Colors,
  fontSize: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}
export const SlideTextEditor = () => {

  const [state, setState] = React.useState<TextSlideProp>({
    text: '',
    backgroundColor: Colors.WHITE,
    textColor: Colors.GRAY,
    fontSize: 'md'
  })
  const [fontSize] = React.useState<number>(24)
  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  )

  const handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(
      editorState,
      command
    )
    if (newState) {
      setEditorState(newState)
      return "handled"
    }
    return "not-handled"
  }

  const handleUnderlineEvent = () => {
		setEditorState(
			RichUtils.toggleInlineStyle(editorState, "UNDERLINE")
		);
	};

	const handleBoldEvent = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
	};

	const handleItalicEvent = () => {
		setEditorState(
			RichUtils.toggleInlineStyle(editorState, "ITALIC")
		);
	};
  console.log(editorState.getCurrentContent())
  return <React.Fragment>
    <SlideBuilderToolBox
      onBackgroundColorChange={(c) => setState({ ...state, backgroundColor: c })}
      onTextColorChange={(c) => setState({ ...state, textColor: c })}
      className=''
      backgroundColor={state.backgroundColor}
      textColor={state.textColor}
      onBoldEvent={handleBoldEvent}
      onItalicEvent={handleItalicEvent}
      onUnderlineEvent={handleUnderlineEvent}
    />
    {/* <article className={cn(
      [
        state.textColor === Colors.WHITE ? 'text-white' : `text-${state.textColor}-600`,
        `bg-${state.backgroundColor}-700`,
        'w-1/2',
        'border border-gray-100 shadow-lg align-middle text-center w-full'
      ]
    )} style={{ height: '400px', verticalAlign: 'middle', fontSize }} contentEditable>
    </article> */}
    <article 
      className={cn('border border-gray-100 shadow-lg align-middle text-center w-full')}
      style={{height: '500px'}}
    >
      <Editor
        editorState={editorState}
        onChange={setEditorState}
        handleKeyCommand={handleKeyCommand}
        placeholder="Type here..."
      />
    </article>
  </React.Fragment>

}