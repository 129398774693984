import { InputText } from 'components/InputText';
import * as React from 'react'
import { OrgRoles } from 'api/models';
import { IdName, ProjectRolesProps } from 'api/models';
import { ReactComponent as IconAdd } from 'assets/Icons/add.svg'
import { ReactComponent as IconRemove } from 'assets/Icons/close.svg'
import { OutlineButton } from 'components/Buttons';

interface P {
  email: string
  project?: IdName
  org: IdName
  name: string
  org_roles: OrgRoles[]
  project_roles: ProjectRolesProps[]
}

interface T {
  name: string
  email: string
  onNameChange(e): void
  onEmailChange(e): void
  onAdd(): void
  onRemove(): void
}

export const MemberInvitation:React.FC<T> = ({
  name,
  email,
  onNameChange,
  onEmailChange,
  onAdd,
  onRemove
}) => {
  return <div className='grid grid-cols-12 gap-2 border-t border-gray-100 items-center p-4 px-6'>
    <div className='col-span-5'><InputText placeholder='Full Name' inline onChange={onNameChange} /></div>
    <div className='col-span-5'><InputText placeholder='Email' inline onChange={onEmailChange} /></div>
    <div><OutlineButton className='self-center' onClick={onAdd}><IconAdd height='20' width='20' /></OutlineButton></div>
    <div><OutlineButton className='self-center' onClick={onRemove}><IconRemove height='20' width='20' /></OutlineButton></div>
  </div>
}