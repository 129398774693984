import { get, save, subscribe } from "api";
import { Collections } from "api/firebase";
import { LearningTrackProps } from "LearningTracks/model";
import { Cohort, LearningTrackMember } from "./models";

export const fetchLearningTracks = (org_id:string, callback) => {
  return subscribe(Collections.LEARNING_TRACKS)
    .where('org_id', '==', org_id)
    .onData((response) => {
      callback(response as LearningTrackProps[])
    })
}

export const addLearningTrack = (track:LearningTrackProps) => {
  return save(Collections.LEARNING_TRACKS, track)
}

export const getLearningTrack = async(trackId:string) => {
  const resp = await get(Collections.LEARNING_TRACKS, trackId)
  return resp as LearningTrackProps
}

export const getLearningTrackMembers = (trackId:string, handler) => {
  return subscribe(Collections.LEARNING_TRACK_MEMBERS)
    .where('track.id', '==', trackId)
    .onData(resp => {
      handler(resp as LearningTrackMember[])
    })
}

export const addLearningTrackMember = (member) => {
  return save(Collections.LEARNING_TRACK_MEMBERS, member)
}

export const saveCohort = (cohort:Cohort) => {
  return save(Collections.COHORTS, cohort)
}

export const fetchCohorts = (trackId:string, handler) => {
  return subscribe(Collections.COHORTS).where('track.id', '==', trackId)
    .onData(docs => {
      handler(docs as Cohort[])
    })
}