import { User } from 'auth/auth-model'
import { getRandomString } from 'helpers'
import { Message, MessageType } from 'helpers/messages'
import * as React from 'react'
import { OrgRoles } from 'api/models'
import { OrgMembersInvitationForm } from './OrgMembersInvitationForm'
import { inviteOrgMembers } from 'api/orgs'
import { ModalContext } from 'components/Modal'

interface P {
  org_id: string
  afterSubmit?: () => void
}

const initialMember = {
  email: '',
  uid: '',
  name: '',
  password: '',
  role: ''
}
export const OrgMembersInvitation:React.FC<P> = ({ org_id, afterSubmit }) => {
  const [members, setMember] = React.useState<User[]>([])
  const [roles, setRoles] = React.useState<{label:string, value:string}[]>([])
  const [currentRole, setCurrentRole] = React.useState('')
  const [message, setMessage] = React.useState<Message>()
  const [isWorking, toggleIsWorking] = React.useState(false)
  const { closeModal } = React.useContext(ModalContext)

  const prepareUser = () => {
    const user = { ...initialMember }
    user.uid = getRandomString(8)
    user.password = getRandomString(8)
    user.role = currentRole
    return user
  }

  const setOrgRoles = () => {
    const roles = Object.keys(OrgRoles).map(key => ({value: key, label: OrgRoles[key]}))
    setRoles(roles)
  }

  const addUser = () => {
    const user = prepareUser()

    const membersDraft = [...members]
    membersDraft.push(user)
    setMember(membersDraft)
  }

  const updateUser = (id, field, value) => {
    if (!currentRole.length) {
      setMessage({text: 'Please select a role for the users', type: MessageType.error})
    }
    const membersDraft = [...members]
    const currentUserIndex = members.findIndex(member => (id === member.uid))
    const currentUser:User = {
      ...members[currentUserIndex],
      [field]: value
    }
    membersDraft.splice(currentUserIndex, 1, currentUser)
    setMember(membersDraft)
  }

  const removeUser = (id) => {
    const membersDraft = [...members]
    const currentUserIndex = members.findIndex(member => (id === member.uid))
    membersDraft.splice(currentUserIndex, 1)
    setMember(membersDraft)
  }

  const handleRoleChange = (role) => {
    setMessage(undefined)
    setCurrentRole(role)
  }

  const updateInvitesOnRoleChange = () => {
    if (!(currentRole && currentRole.length)) {
      return
    }
    const draft = members.map(member => {
      return {...member, role: currentRole}
    })
    setMember(draft)
  }

  React.useEffect(updateInvitesOnRoleChange, [currentRole])
  React.useEffect(addUser, [])
  React.useEffect(setOrgRoles, [])

  const handleSendInvitations = React.useCallback(() => {
    toggleIsWorking(true)
    inviteOrgMembers(org_id, members)
      .then(closeModal)
      .catch(err => {
        setMessage({text: 'Invitation failed. Please try again', type: MessageType.error})
      })
    
  }, [closeModal, members, org_id])

  return <OrgMembersInvitationForm
    currentRole={currentRole}
    onRoleChange={handleRoleChange}
    roles={roles}
    removeRow={removeUser}
    members={members} 
    addRow={addUser}
    onEmailChange={updateUser}
    onNameChange={updateUser}
    message={message}
    onSendInvitations={handleSendInvitations}
    isWorking={isWorking}
  />
}