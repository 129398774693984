import * as React from 'react'
import { Link } from 'react-router-dom'

interface T {
  className?: string
  gap?: number
}
export const Tab:React.FC<T> = ({ children, className, gap='6' }) => {
  const styles = className
  return <div className={`flex gap-${gap} ${styles}`}>
    {children}
  </div>
}

interface IT {
  className?:string
  to:string
  active?: boolean
}

export const TabItem:React.FC<IT> = ({ children, to, className, active = false }) => {
  const style = ['border-b-2', 'hover:bg-gray-100', 'hover:border-primary-500', 'p-2']

  if (active) {
    style.push('border-primary-500')
  } else {
    style.push('border-transparent')
  }

  return <Link to={to} className={`flex 
  ${style.join(' ')} ${className}`}>{children}</Link>
}