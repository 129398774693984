import * as React from 'react'
import { LandingNavbar } from './LandingNavbar'
import Mentor from '../assets/Mentor.jpeg'

import { Siftora } from '../Siftora'

export const LandingPage = () => {
  const styles = {
    root: {
      background: 'linear-gradient(135deg, rgba(255,112,67,1) 0%, rgba(255, 138, 101) 100%)'
    },
    withBg: {
      backgroundImage: `url(${Mentor})`,
      height: window.screen.availHeight * .8,
      backgroundPosition: 'cover'
    }
  }
  return <>
    <LandingNavbar className='bg-primary-400 shadow-lg p-2' />
    
    <div 
      className='bg-white text-center align-center items-center flex-wrap bg-no-repeat bg-contain flex px-4 sm:pl-10 md:pl-20 pt-20'
      style={styles.withBg}
    >
      
      <div className='max-w-md text-left'>
        {/* <div className='text-xl md:text-2xl xl:text-4xl font-serif font-bold text-left'>
          Influencing knowledge retention and professional behavioural changes
        </div>
        <div className='container mx-auto'>
          <Button color='primary' fill raised>Chat with us</Button>
        </div> */}
        <div className='text-gray-700 md:text-xl'>
          <Siftora className='text-6xl' color='primary' />
          An Innovative application that uses  Science and Technology to Influence knowledge retention and behavioral change in capacity building.
          <div className='text-primary-400'>Coming Soon!</div>
        </div>
        {/* <InputTextButton label='Sign Up for early access invite' placeholder='Email' /> */}
      </div>
    </div>

    {/* <div className='md:flex justify-around p-4 py-6'>
      <div className='mb-2 sm:mb-0 bg-white md:w-3/5'>
        <div className="rounded overflow-hidden flex flex-wrap justify-center">
          <img className="w-2/3" src={Trainer} alt="Sunset in the mountains" />
          
        </div>
      </div>
      <div className='mb-2 sm:mb-0 bg-white md:w-2/5 bg-gray-200'>
        <div className="rounded overflow-hidden flex flex-wrap">
          <img className="w-2/3" src={Trainees} alt="Sunset in the mountains" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 text-primary-400">Trainers</div>
            <p className="text-gray-700 text-base">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
            </p>
            <div className="px-6 py-4">
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#photography</span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#travel</span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">#winter</span>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white md:w-1/3'>
        <div className="max-w-sm rounded overflow-hidden">
          <img className="w-full" src="https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80" alt="Sunset in the mountains" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
            <p className="text-gray-700 text-base">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
            </p>
          </div>
          <div className="px-6 py-4">
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#photography</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#travel</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">#winter</span>
          </div>
        </div>
      </div>
    </div> */}
    <div className='bg-gray-400 h-48'>
      
    </div>
  </>
}