import { Collections, fdb, getCurrentUser } from 'api/firebase'
import { firestore } from 'firebase'
import config from '../config/app-config.json'
import { SiftoraRecord } from './models'
const env = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV
interface API_RESPONSE {
  error: boolean
  message?: string
}


export const inviteProjectMembers = async (projectID, members) => {
  const response:API_RESPONSE = {error: false}
  try {
    const currentUser = getCurrentUser()
    const idToken = await currentUser?.getIdToken()
    
    fetch(`${config[env].functions}/users/v1/projects/${projectID}`, {
        method: 'POST',
        // this triggers CORS errors
        // headers: {
        //   'Accept': 'application/json',
        //   'Content-Type': 'application/json'
        // },
        body: JSON.stringify({members, idToken}),
      })
  } catch(e) {
    response.error = true
    response.message = e?.message
    console.error(e)
  }
  return response
}

type C = (response: SiftoraRecord[]) => void

export const subscribe = (collection:Collections) => {
  let query: firestore.Query<firestore.DocumentData>
  const collectionRef = fdb.collection(collection)

  /**
   * 
   * @param callback : function that takes array of firestore DocumentDara as param
   * 
   * @returns function to unsubscribe
   */
  const onData = (callback:C) => {
    if (!query) {
      query = collectionRef
    }
    return query.onSnapshot((snap) => {
      const response:SiftoraRecord[] = []
      snap.docs.forEach(doc => {
        const data:SiftoraRecord = { ...doc.data(), id: doc.ref.id }
        response.push(data)
      })
      callback(response)
    })
  }

  const where  = (prop: string, compare: firestore.WhereFilterOp, value:string) => {
    query = query ? query.where(prop, compare, value) : collectionRef.where(prop, compare, value)
    return {
      where,
      onData
    }
  }
  
  return {
    where,
    onData
  }
}

export const save = (collection:Collections, doc) => {
  const collectionRef = fdb.collection(collection).doc()
  return collectionRef.set(doc)
}

export const get = (collection:Collections, id) => {
  return fdb.collection(collection).doc(id)
    .get().then(resp => {
      if (resp.exists) {
        const data = resp.data()
        return {
          ...data,
          id: resp.id,
        } as SiftoraRecord
      }
      return null
    })
}

export const update = (collection:Collections, id:string, doc) => {
  return fdb.collection(collection).doc(id)
  .update(doc)
}

export const find = (collection) => {
  return fdb.collection(collection)
}

export const convertFBTimeToDate = (field: string, doc: SiftoraRecord) => {
  if (!doc[field]) return doc
  doc[field] = doc[field].toDate()
  return doc
}