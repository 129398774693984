import { inviteProjectMembers } from 'api'
import { User } from 'auth/auth-model'
import { getRandomString } from 'helpers'
import { Message, MessageType } from 'helpers/messages'
import * as React from 'react'
import { ProjectRolesProps } from 'api/models'
import { ProjectMembersInvitationForm } from './ProjectMembersInvitationForm'

interface P {
  project_id: string
  afterSubmit?: () => void
}

const initialMember = {
  email: '',
  uid: '',
  name: '',
  password: '',
  role: ''
}
export const ProjectMembersInvitation:React.FC<P> = ({ project_id, afterSubmit }) => {
  const [members, setMember] = React.useState<User[]>([])
  const [roles, setRoles] = React.useState<{label:string, value:string}[]>([])
  const [currentRole, setCurrentRole] = React.useState('')
  const [message, setMessage] = React.useState<Message>()

  const prepareUser = () => {
    const user = { ...initialMember }
    user.uid = getRandomString(8)
    user.password = getRandomString(8)
    user.role = currentRole
    return user
  }

  const setProjectRoles = () => {
    const roles = Object.keys(ProjectRolesProps).map(key => ({value: key, label: ProjectRolesProps[key]}))
    setRoles(roles)
  }

  const addUser = () => {
    const user = prepareUser()

    const membersDraft = [...members]
    membersDraft.push(user)
    setMember(membersDraft)
  }

  const updateUser = (id, field, value) => {
    if (!currentRole.length) {
      setMessage({text: 'Please select a role for the users', type: MessageType.error})
    }
    const membersDraft = [...members]
    const currentUserIndex = members.findIndex(member => (id === member.uid))
    const currentUser:User = {
      ...members[currentUserIndex],
      [field]: value
    }
    membersDraft.splice(currentUserIndex, 1, currentUser)
    setMember(membersDraft)
  }

  const removeUser = (id) => {
    const membersDraft = [...members]
    const currentUserIndex = members.findIndex(member => (id === member.uid))
    membersDraft.splice(currentUserIndex, 1)
    setMember(membersDraft)
  }

  const handleRoleChange = (role) => {
    setMessage(undefined)
    setCurrentRole(role)
  }

  const updateInvitesOnRoleChange = () => {
    if (!(currentRole && currentRole.length)) {
      return
    }
    const draft = members.map(member => {
      return {...member, role: currentRole}
    })
    setMember(draft)
  }

  React.useEffect(updateInvitesOnRoleChange, [currentRole])
  React.useEffect(addUser, [])
  React.useEffect(setProjectRoles, [])

  const handleSendInvitations = React.useCallback(() => {
    inviteProjectMembers(project_id, members)
    if (afterSubmit) {
      afterSubmit()
    }
  }, [afterSubmit, members, project_id])

  return <ProjectMembersInvitationForm
    currentRole={currentRole}
    onRoleChange={handleRoleChange}
    roles={roles}
    removeRow={removeUser}
    members={members} 
    addRow={addUser}
    onEmailChange={updateUser}
    onNameChange={updateUser}
    message={message}
    onSendInvitations={handleSendInvitations}
  />
}