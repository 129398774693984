import { useAuthSelector } from 'auth/auth-reducer';
import { Collections, fdb } from 'api/firebase';
import * as React from 'react'
import { useSelector } from 'react-redux';
import { ProjectMemberProps } from 'api/models';
import { MenuLayout } from './MenuLayout';

interface P {
  org_id: string
  project_id: string
}

export const ProjectMenu:React.FC<P> = ({ org_id, project_id }) => {
  const { data: user } = useSelector(useAuthSelector)
  
  React.useEffect(() => {
    const projectMembershipRef = fdb.collection(Collections.PROJECT_MEMBERS)
      .where('project.id', '==', project_id)
      .where('user.id', '==', user.uid)

      projectMembershipRef.get().then(projectMember => {
        projectMember.docs.forEach(doc => {
          const pm = doc.data() as ProjectMemberProps
          if (!(pm.disabled && pm.is_active)) {
            doc.ref.update({is_active: true})
          }
        })
      })
  })
  return <MenuLayout  project_id={project_id} />
} 