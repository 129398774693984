import * as React from 'react'
import { Avatar } from 'components/Avater'
import { ProgressBar } from 'components/ProgressBar'
import { ReactComponent as CalendarIcon } from 'assets/Icons/calendar.svg'
import { ReactComponent as GoalIcon } from 'assets/Icons/checklist.svg'
import { ReactComponent as TimelineIcon } from 'assets/Icons/timeline.svg'
import { ReactComponent as StopFolderIcon } from 'assets/Icons/stop-folder.svg'
import { Link, useParams } from 'react-router-dom'
import { InputText } from 'components/InputText'
import { format, isValid } from 'date-fns'
import { Colors } from 'helpers/colors'

export const MentorshipLayout = ({ 
  mentorship, 
  handleDurationChange, 
  handleStartDateChange, 
  handleUpdateMentorshipRecord,
  progress
}) => {
  const { org_id, mentorshipId } = useParams<{org_id:string, mentorshipId: string}>()
  const getView = () => {
    
    return <React.Fragment>
      <div className='bg-white p-2 border-b-2 border-gray-100'>{mentorship.track.name}</div>
      <div className=''><ProgressBar progress={progress} color={Colors.SECONDARY} /></div>
      <div className='flex gap-8 items-center text-sm bg-white'>
        <div className='p-4 justify-center items-center flex'>
          <Avatar user={mentorship.mentor} />
          <span className=''>
            {mentorship.mentor.name}
            <p className='text-xs'>Mentor</p>
          </span>
        </div>
        <div className='p-4 justify-center items-center'>
          <Avatar user={mentorship.mentee} />
          <span className=''>
            {mentorship.mentee.name}
            <p className='text-xs'>Mentee</p>
          </span>
        </div>
        <div className='flex gap-12 justify-center flex-1 p-4'>
          <Link to={`/orgs/${org_id}/mentorship/${mentorshipId}/schedule`} className='flex items-center justify-center text-primary-500'>
            <CalendarIcon height='24' className='stroke-current' /> <span className=''>Schedule</span>
          </Link>
          <Link to={`/orgs/${org_id}/mentorship/${mentorshipId}/goals`} className='flex items-center justify-center text-cyan-500'>
            <GoalIcon height='24' className='stroke-current' /><span className=''>Goals</span>
          </Link>
          <Link to={`/orgs/${org_id}/mentorship/${mentorshipId}/timeline`} className='flex items-center justify-center'>
            <TimelineIcon height='24' />Timeline
          </Link>
          <Link to='/' className='bg-red-600 text-white p-2 text-xs flex items-center self-end justify-self-end'>
            <StopFolderIcon height='20' className='stroke-current' />
            Discontinue
          </Link>
        </div>
      </div>
      <div className='p-4 flex gap-8 bg-white items-center'>
        <InputText type='date' label='Start date' 
          onChange={(e) => handleStartDateChange(e.target.value) } 
          value={isValid(mentorship.start_date) ? format(mentorship.start_date, 'yyyy-MM-dd') : ''}
          className='border-none'
          onBlur={() => handleUpdateMentorshipRecord()}
        />
        <InputText 
          type='number' 
          label='Duration(months)' 
          value={mentorship.period}
          onChange={(e) => handleDurationChange(e.target.value)}
          onBlur={() => handleUpdateMentorshipRecord()}
        />
        
      </div>
      <div>
        <div className='grid grid-cols-2 gap-2 mt-2 justify-items-stretch'>
          <div className='bg-white'>
            <div className='border-b-2 border-gray-100 p-2'>Mentor's Checkins</div>
            <span className='p-4'>No checkins submitted by mentor</span>
          </div>
          <div className='bg-white'>
            <div className='border-b-2 border-gray-100 p-2'>Mentee's Checkins</div>
            <span className='p-4'>No checkins submitted by mentee</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  }

  return <div className=' text-sm'>  
    {getView()}
  </div>
}