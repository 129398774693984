import * as React from 'react'
import { OrgMember } from 'api/models'
import { Logout } from 'components/Logout'
import { MenuItem, MenuItemIcon, MenuItemText } from 'components/MenuItem'
import { ReactComponent as IconDashboard } from 'assets/Icons/menu/dashboard.svg'
import { ReactComponent as IconTrack } from 'assets/Icons/menu/track.svg'
import { ReactComponent as IconTraining } from 'assets/Icons/menu/training.svg'
import { ReactComponent as IconMentor } from 'assets/Icons/menu/network.svg'
import { ReactComponent as IconSupervisor } from 'assets/Icons/menu/supervisor.svg'
import { ReactComponent as IconStudio } from 'assets/Icons/menu/studio.svg'
import { ReactComponent as IconMembers } from 'assets/Icons/menu/queue.svg'
import { UserToolBar } from 'Home/ToolBar'
import { OrgComponentsRoutes } from './OrgComponentRoutes'
interface P {
  activeUserOrg: OrgMember
}

export const OrgDetail: React.FC<P> = ({ activeUserOrg }) => {
  const { org: { id } }=  activeUserOrg

  return <div className='min-h-screen bg-gray-100 md:flex'>
    <div 
      className='bg-gray-200 justify-between py-4 px-0 text-primary-700 hidden md:w-56 md:flex md:flex-col'>
      <div className=''>
        <UserToolBar />
        <MenuItem to={`/orgs/${id}`} className=''>
          <MenuItemIcon className='text-primary-400'>
            <IconDashboard 
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Dashboard</MenuItemText>
        </MenuItem>
        <MenuItem to={`/orgs/${id}/tracks`}>
          <MenuItemIcon className='text-primary-400'>
            <IconTrack
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Learning Tracks</MenuItemText>
        </MenuItem>
        <MenuItem to={`/orgs/${id}/trainings`}>
          <MenuItemIcon className='text-primary-400'>
            <IconTraining
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Trainings</MenuItemText>
        </MenuItem>
        <MenuItem to={`/orgs/${id}/mentorship`}>
          <MenuItemIcon className='text-primary-400'>
            <IconMentor
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Mentorship</MenuItemText>
        </MenuItem>
        <MenuItem to={`/orgs/${id}/supervision`}>
          <MenuItemIcon className='text-primary-400'>
            <IconSupervisor
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Supportive Supervisor</MenuItemText>
        </MenuItem>
        <MenuItem to={`/orgs/${id}/studio`}>
          <MenuItemIcon className='text-primary-400'>
            <IconStudio
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Studio</MenuItemText>
        </MenuItem>
        <MenuItem to={`/orgs/${id}/members`}>
          <MenuItemIcon className='text-primary-400'>
            <IconMembers
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Members</MenuItemText>
        </MenuItem>
      </div>
      <div className='justify-end'>
        <Logout />
      </div>
    </div>
    <div className='flex-grow flex-shrink'>
      <OrgComponentsRoutes />
    </div>
  </div>
} 