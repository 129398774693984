import firebase from 'firebase/app'
import "firebase/auth"
import "firebase/firestore"
import 'firebase/storage'
import config from '../config/app-config.json'

const env = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV

firebase.initializeApp(config[env].firebase)

const auth = firebase.auth()
const fdb = firebase.firestore()
const Storage = firebase.storage().ref()

export enum Collections {
  ORGS = 'orgs',
  ORG_MEMBERS = 'org_members',
  PROJECTS = 'projects',
  PROJECT_MEMBERS = 'project_members',
  LEARNING_TRACKS = 'learning_tracks',
  TRAININGS = 'trainings',
  LEARNING_TRACK_MEMBERS = 'learning_track_member',
  COHORTS = 'cohorts',
  MENTOR_MENTEE = 'mentor_mentee',
  TRAINING_SESSIONS = 'training_sessions',
  MEDIA = 'media',
  SLIDEDECKS = 'slide_deck',
  SLIDES = 'slides'
}

const getIdToken = () => {
  auth.currentUser?.getIdToken()
}

const getCurrentUser = () => {
  return auth.currentUser
}

export { auth, fdb, getIdToken, getCurrentUser, Storage }