import { OutlineButton } from 'components/Buttons'
import { NameAvatar } from 'components/Avater/NameAvatar'
import * as React from 'react'
import { ProjectMemberProps } from 'api/models'
import { ReactComponent as IconOrgMembers } from 'assets/Icons/crowd.svg'
import { ReactComponent as IconMemberInvite } from 'assets/Icons/mail.svg'
import { ProjectRolesMenu } from '../ProjectRolesMenu'

interface P {
  members: ProjectMemberProps[]
  openModal(): void
}

export const MembersList:React.FC<P> = ({ members, openModal }) => {
  const getMembersScreen = () => {
    return members?.map(member => {
      let status
      if (member.disabled) {
        status = <span>Disabled</span>
      } else {
        status = member.is_active
          ? <span className='text-xs bg-green-200 rounded-full p-2'>Active</span>
          : <span className='bg-red-200 rounded-full text-xs p-2'>Inactive</span>
      }
      return <div className={`
        pointer-events-auto grid grid-cols-12
        px-2 py-4 items-center
        text-sm mt-2 border-gray-100 border-b ${member.disabled ? 'text-gray-200': ''}`} 
        key={member.user.id}>
        <div><NameAvatar name={member.user.name} /></div>
        <div className='col-span-3'>{member.user.name}</div>
        <div className='col-span-3'>{member.user.email}</div>
        <div className='col-span-3 capitalize flex justify-between'>
          {member.roles.join(', ')}
        </div>
        <div className='capitalize'>
          {status}
        </div>
        <ProjectRolesMenu member={member}  />
      </div>
    })
  }

  if (!members.length) {
    return <div>No Members found</div>
  }

  return <div className='bg-white'>
    <div className='border-gray-100 border-b p-2 flex items-center justify-between'>
      <div className='flex text-gray-600'>
        <IconOrgMembers height='24' className='stoke-current fill-current mr-2' />
        Members
      </div>
      <div>
        <OutlineButton
          color='secondary' 
          onClick={openModal}
          rounded
          className='text-sm'>
            <IconMemberInvite className='stroke-current' height='20' />
            Invite Members
        </OutlineButton>
      </div>
    </div>
    {getMembersScreen()}
  </div>
}