import * as React from 'react'
import { useParams } from 'react-router-dom'
import { SplashScreen } from 'layouts/SplashScreen'
import { OrgDetail } from './OrgDetails'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_USER_ACTIVE_ORG, useGetDefaultActiveOrg, useUserOrgs } from 'orgs/orgs-reducers'

interface T {
  org_id?:string
}
export const OrgHome:React.FC<T> = ({ org_id }) => {
  const activeUserOrg = useSelector(useGetDefaultActiveOrg)
  const params = useParams()
  const orgId = params['org_id'] || org_id

  const userMemberships = useSelector(useUserOrgs)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const activeOrg = userMemberships.find(membership => {
      return membership.org.id === orgId
    })
    if (activeOrg) {
      dispatch({type: CHANGE_USER_ACTIVE_ORG, data: activeOrg})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  if (!activeUserOrg.id) {
    return <SplashScreen />
  }
  return <OrgDetail activeUserOrg={activeUserOrg} />
}