import * as React from 'react'
import { Modal, ModalContext } from 'components/Modal'
import { ModalHeader } from 'components/Modal/ModalHeader'
import { useParams } from 'react-router'
import { addLearningTrackMember, getLearningTrack } from 'api/learning-tracks'
import { Collections } from 'api/firebase'
import { LearningTrackMember, OrgMember, OrgRoles, Cohort } from 'api/models'
import  * as api  from 'api'
import { LearningTrackProps } from 'LearningTracks/model'
import { ModalFooter } from 'components/Modal/ModalFooter'
import { Button, OutlineButton } from 'components/Buttons'
import { ModalBody } from 'components/Modal/ModalBody'
import { Avatar } from 'components/Avater'

interface T {
  cohort: Cohort
}

export const AddCohortLearners:React.FC<T> = ({ cohort }) => {
  const { trackId, org_id } = useParams<{trackId: string, org_id: string}>()

  const [learners, setLearner] = React.useState<OrgMember[]>([])
  const [track, setTrack] = React.useState<LearningTrackProps>()

  const { closeModal } = React.useContext(ModalContext)

  React.useEffect(() => {
    api.subscribe(Collections.ORG_MEMBERS).where('org.id', '==', org_id as string).onData((data) => {
      const mentors = data.filter(member => member.roles.includes(OrgRoles.GUEST))
      setLearner(mentors as OrgMember[])
    })
  }, [org_id])

  React.useEffect(() => {
    getLearningTrack(trackId)
      .then(track => setTrack(track))
      .catch(err => console.error(err))
  }, [trackId])

  const handleAddAsLearner = React.useCallback((learner:OrgMember) => {
    if (!track) {
      return // Hope it never comes to this else we are screwed
    }
    const member:LearningTrackMember = {
      user: learner.user,
      track: { id: track.id as string, name: track.name },
      is_active: true,
      role: 'Learner',
      created_at: new Date(),
      disabled: false,
      id: `${trackId}-${learner.user.id}`,
      cohort
    }
    addLearningTrackMember(member)
    closeModal()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, trackId, cohort])

  const listLearners = () => {
    if (!learners.length) {
      return <div className='text-gray-600'>Your organisation have not assigned anybody as a Learner</div>
    }
    return learners.map((mentor) => {
      return <div className='grid grid-cols-8 text-gray-600 p-4' key={mentor.id}>
        <Avatar user={mentor.user} />
        <div className='col-span-5'>{mentor.user.name}</div>
        <div className='col-span-2'>
          <OutlineButton color='secondary' className='rounded' onClick={() => handleAddAsLearner(mentor)}>Add As Learner</OutlineButton>
        </div>
      </div>
    })
  }

  return <Modal>
    <React.Fragment>
      <ModalHeader title={`Add To Cohort: ${cohort.name}`} />
      <ModalBody>
        {listLearners()}
      </ModalBody>
      <ModalFooter>
        <Button>Cancel</Button>
      </ModalFooter>
    </React.Fragment>
  </Modal>
}