import { Collections, fdb } from "api/firebase";
import { ProjectMemberProps, ProjectRolesProps } from "api/models";
import * as api from '.'

interface A {
  (member:ProjectMemberProps, role:ProjectRolesProps)
}

interface UMR {
  (
    member:ProjectMemberProps,
    newRole: ProjectRolesProps,
  ): Promise<any>
}
export const addMemberRole:A = (member, role) => {
  member.roles.push(role)
  return fdb.collection(Collections.PROJECT_MEMBERS)
    .doc(member.uid).update(member)
}

export const removeMemberRole:A = (member, role ) => {
  const roleIndex = member.roles.findIndex(userRole => userRole === role)
  member.roles.splice(roleIndex, 1)
  fdb.collection(Collections.PROJECT_MEMBERS).doc(member.uid).update(member)
}

export const updateProjectMemberRoles:UMR = (
  member,
  newRole,
) => {
  if (member.roles.includes(ProjectRolesProps.TRAINEE)) {
    if (newRole === ProjectRolesProps.TRAINEE) {
      return removeMemberRole(member, newRole)
    } else {
      return Promise.reject('Trainees can not have other roles')
    }
  }
  if (newRole === ProjectRolesProps.TRAINEE) { 
    member.roles.length = 0
    return addMemberRole(member, newRole)
  }

  if (member.roles.includes(newRole)) {
    return removeMemberRole(member, newRole)
  }
  
  return addMemberRole(member, newRole)
}

interface M {
  (member:ProjectMemberProps): void
}
export const toggleMembershipStatus:M = ( member ) => {

  fdb.collection(Collections.PROJECT_MEMBERS).doc(member.uid).update({
    ...member,
    disabled: !member.disabled
  })
}

export const getProjectMembership = (userId:string, projectID:string):Promise<ProjectMemberProps | null> => {
  return api.find(Collections.PROJECT_MEMBERS)
    .where('user.id', '==', userId)
    .where('project.id', '==', projectID)
    .get().then(docData => {
      if (docData.empty) {
        return null
      }
      return docData.docs[0].data() as ProjectMemberProps
    })
}