import { combineReducers, applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import auth from './auth/auth-reducer'
import {
  userOrgsReducer as user_orgs,
  activeUserOrg as active_org
} from 'orgs/orgs-reducers'


const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
)

const reducers = combineReducers({
  auth,
  user_orgs,
  active_org
})

const store = createStore(reducers, enhancer)

export { store }
