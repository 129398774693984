import { OutlineButton } from 'components/Buttons'
import * as React from 'react'
import { ReactComponent as IconMore } from 'assets/Icons/chevron.svg'
import { DropDown, DropDownItem } from 'components/DropDownMenu'

export const CohortSelect = ({ cohorts, activeCohort, onCohortChange }) => {
  const [isOpen, toggleIsOpen] = React.useState(false)
  
  let rows = [<DropDownItem key='no-cohort'>No Cohorts for Learning Track</DropDownItem>]
  if (cohorts.length && activeCohort) {
    rows = cohorts.map(cohort => {
      return <DropDownItem key={cohort.id} onClick={() => onCohortChange(cohort)}>
        {cohort.name}
      </DropDownItem>
    })
  }
  return <React.Fragment>
    <OutlineButton className='w-full items-center' onClick={() => toggleIsOpen(!isOpen)}>
      {activeCohort && activeCohort.name} 
      <IconMore height='16' className='stroke-current' />
    </OutlineButton>
      <DropDown isOpen={isOpen} className='w-full' onMouseLeave={() => toggleIsOpen(false)}>
        { rows }
      </DropDown>
  </React.Fragment>
}