import * as React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { Colors } from 'helpers/colors'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'white' | Colors
  children: (string | React.ReactElement) | (string | React.ReactElement)[]
  raised?: boolean,
  fill?: boolean,
  to?: string,
  disabled?: boolean
  rounded?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  onClick,
  fill = false,
  raised = false,
  color,
  to = '',
  disabled,
  rounded = true
}) => {
  const style = {
    'py-2': true,
    'px-4': true,
    'rounded-full': rounded,
    flex: true,
    'focus:outline-none': true,
    'text-small': true
  }

  if (color) {
    if (color === 'white') {
      style[`bg-white`] = true
      style[`text-${color}-400`] = true
    } else {
      style[`bg-${color}-400`] = true
      style[`text-white`] = true
    }
  }

  if (raised) {
    style['shadow-xl'] = true
  }

  if (disabled) {
    style['cursor-not-allowed'] = true
    style['opacity-50'] = true
  }

  const classes = cn(style, className)
  let component
  if (to && to.includes('/')) {
    component = <Link className={classes} to={to}>{children}</Link>
  } else {
    component = <button className={classes} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  }

  return component
}

export const OutlineButton: React.FC<ButtonProps> = ({
  className,
  children,
  onClick,
  fill = false,
  raised = false,
  color,
  to = '',
  disabled,
  rounded
}) => {
  const style = {
    'p-2': true,
    'rounded-full': rounded,
    flex: true,
    'justify-between': true,
    'hover:bg-gray-100': true
  }

  if (color) {
    if (color === 'white') {
      style[`bg-white`] = true
      style[`text-${color}-400`] = true
      
    } else {
      // style[`hover:border-${color}-400 border`] = true
      style[`text-${color}-400`] = true
      style[`hover:text-${color}-700`] = true
    }
  } else {
    style[`text-gray-600`] = true
  }

  if (raised) {
    style['shadow-xl'] = true
  }

  if (disabled) {
    style['cursor-not-allowed'] = true
    style['opacity-50'] = true
  }

  const classes = cn(style, className)

  let component
  if (to && to.includes('/')) {
    component = <Link className={classes} to={to}>{children}</Link>
  } else {
    component = <button className={classes} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  }

  return <span className={className}>
    { component }
  </span>
}