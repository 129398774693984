import { firestore } from "firebase"
// Utility models
export interface SiftoraRecord extends firestore.DocumentData {
  id: string
  created_at?: Date
  created_by?: IdName
  is_active?: boolean
  disabled?: boolean,
  name?: string
}

export type IdName = {
  id: string
  name: string
  display_image?:string
}

// Training

export interface TrainingProps {
  id: string
  title: string
  description: string
  active?: boolean
  org: IdName
  learning_track: IdName
}

export interface TrainingPlanProps {
  id:string
  training: IdName
}

export enum DurationTypes {
  DAYS = 'days', 
  WEEKS = 'weeks',
  MONTHS =  'months'
}

export interface TrainingSessionsProps {
  name: string
  training: IdName
  start_date: Date
  duration: number
  duration_type: DurationTypes
  id: string
}

export interface TrainingModule {
  training_id
}

// Orgs
export enum OrgTypes {
  public = 'Public',
  private = 'Private',
  nonProfit = 'Non-Profit'
}

export enum OrgSectors {
  education = 'Education',
  banking = 'Banking',
  health = 'Health'
}

export interface Org {
  uid?: string
  id?:string
  name: string
  created_at: Date
  created_by: { uid?: string, name: string, id: string }
  org_type?: OrgTypes
  is_active: boolean
}

export enum OrgRoles {
  ADMIN = 'Admin',
  COORDINATOR = 'Coordinator',
  MENTOR = 'Mentor',
  SUPERVISOR = 'Supervisor',
  MEMBER = 'Member',
  GUEST = 'Guest'
}

export interface OrgMember {
  id:string
  user: {
    id: string
    name: string
  }
  org: {
    id: string
    name: string
  }
  active: boolean
  created_at: Date
  roles: OrgRoles[]
  disabled?: boolean
}

// Projects

export interface ProjectProps {
  name: string
  type: string
  sector: string
  created_at: Date
  created_by: IdName
  org_id: string,
  id: string
  is_active: boolean
}

export enum ProjectSectors {
  finance = 'Banking & Finance',
  health = 'Health',
  education = 'Education',
  infoTech = 'Information/Technology',
  agric = 'Agriculture'
}

export enum ProjectRolesProps {
  COORDINATOR = 'cordinator',
  FACILITATOR = 'facilitator',
  MENTOR = 'mentor',
  SUPPORTIVE_SUPERVISOR = 'supportive supervisor',
  TRAINEE = 'trainee'
}

interface U extends IdName {
  email: string
}

export interface ProjectMemberProps {
  project: IdName
  user: U
  created_at: Date
  created_by: IdName
  uid: string
  is_active: boolean
  disabled: boolean
  roles: ProjectRolesProps[]
}

export interface ProjectFeatureProp {
  project_id: string
  feature_id: string
  is_active: boolean
  label: string
  created_at?: Date
}

export interface LearningTrackMember {
  user: IdName
  track: IdName
  created_at?: Date
  is_active: boolean
  disabled?: boolean
  id:string
  role: 'Mentor' | 'Supervisor' | 'Facilitator' | 'Learner'
  cohort?: Cohort
}

export interface Cohort {
  track: IdName
  active: boolean
  name:string
  id: string
}

export interface MentorMentee {
  mentor: IdName
  mentee: IdName
  track: IdName
  start_date: Date | null
  period: number
  id: string,
  org_id: string
}

export interface Mentorship {
  mentor: IdName
  mentee: IdName
  track: IdName
  start_date: Date
  period: number
  id: string,
  org_id: string
}

export enum SupportedFileTypes {
  PNG = 'image/png',
  JPG = 'image/jpg',
  MP4 = 'video/mp4',
  JPEG = 'image/jpeg'
}

export interface Meta {
  title:string
  url:string
  org_id:string
  type: SupportedFileTypes
  id: string
}

export type SlideType = 'Video' | 'Quiz' | 'Text' | 'Break' | 'Break Out'

export interface Slide {
  id:string
  position: number
  training_id: string
  org_id:string
  content: Meta
  time_alloted: number
  slide_deck_id: string
  type: SlideType
}

export type SlideDeck = { 
  id: string
  title:string
  org_id:string
  training_id: string
  length: number
  count: number
}