import * as React from 'react'
import { createPortal } from 'react-dom'

interface P {
  className?: string // Classes to be applied to the main container 
}

export interface ModalContextProps {
  modalIsOpen: boolean
  openModal(): void
  closeModal(): void
}
export const ModalContext = React.createContext({} as ModalContextProps)

export const ModalContextProvider:React.FC = ({ children }) => {
  const [modalIsOpen, toggleModalIsOpen] = React.useState(false)

  const closeModal = React.useCallback(() => toggleModalIsOpen(false), [])
  const openModal = React.useCallback(() => toggleModalIsOpen(true), [])

  return <ModalContext.Provider value={{modalIsOpen, openModal, closeModal}}>{children}</ModalContext.Provider>
}

export const Modal:React.FC<P> = ({ children }) => {
  const { modalIsOpen } = React.useContext(ModalContext)

  const container = <React.Fragment>
    <div className='bg-black opacity-50 h-screen absolute inset-0'></div>
    <div className='bg-transparent absolute inset-0 flex justify-center items-center'>
      <div className='min-h-48 w-full sm:w-4/5 md:w-3/5 shadow-lg'>
        {children}
      </div>
    </div>
  </React.Fragment>

  if (!modalIsOpen) {
    return null
  }
  return createPortal(
    container,
    document.body
  )
}