import { MenuItem, MenuItemIcon, MenuItemText } from 'components/MenuItem'
import * as React from 'react'
import { ReactComponent as IconSettings } from 'assets/Icons/account/settings.svg'
import { ReactComponent as IconDashboard } from 'assets/Icons/menu/dashboard.svg'
import { ReactComponent as IconTrack } from 'assets/Icons/menu/track.svg'
import { ReactComponent as IconTraining } from 'assets/Icons/menu/training.svg'
import { ReactComponent as IconMentor } from 'assets/Icons/menu/network.svg'
import { ReactComponent as IconSupervisor } from 'assets/Icons/menu/supervisor.svg'
import { ReactComponent as IconStudio } from 'assets/Icons/menu/studio.svg'
import { ReactComponent as IconMembers } from 'assets/Icons/menu/queue.svg'

interface P {
  project_id: string
}

export const MenuLayout:React.FC<P>  = ({ project_id }) => {
  return <div 
    className='bg-gray-200 justify-between py-4 px-0 text-primary-700 w-2/5 md:w-1/6 md:flex md:flex-col hidden'>
      <div>
        <MenuItem to={`/`} className=''>
          <MenuItemText className='text-primary-400'>Home</MenuItemText>
        </MenuItem>
        <MenuItem to={`/project/${project_id}`} className=''>
          <MenuItemIcon className='text-primary-400'>
            <IconDashboard 
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Dashboard</MenuItemText>
        </MenuItem>
        <MenuItem to={`/project/${project_id}/tracks`}>
          <MenuItemIcon className='text-primary-400'>
            <IconTrack
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Learning Tracks</MenuItemText>
        </MenuItem>
        <MenuItem to={`/project/${project_id}/trainings`}>
          <MenuItemIcon className='text-primary-400'>
            <IconTraining
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Trainings</MenuItemText>
        </MenuItem>
        <MenuItem to={`/project/${project_id}/mentorship`}>
          <MenuItemIcon className='text-primary-400'>
            <IconMentor
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Mentorship</MenuItemText>
        </MenuItem>
        <MenuItem to={`/project/${project_id}/supervision`}>
          <MenuItemIcon className='text-primary-400'>
            <IconSupervisor
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Supportive Supervisor</MenuItemText>
        </MenuItem>
        <MenuItem to={`/project/${project_id}/studio`}>
          <MenuItemIcon className='text-primary-400'>
            <IconStudio
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Studio</MenuItemText>
        </MenuItem>
        <MenuItem to={`/project/${project_id}/members`}>
          <MenuItemIcon className='text-primary-400'>
            <IconMembers
              height='20'
              width='20'
              className='text-primary-400 sroke-current fill-current' />
          </MenuItemIcon>
          <MenuItemText className='text-primary-400'>Members</MenuItemText>
        </MenuItem>
      </div>
    <div className='justify-end'>
      <MenuItem to='/studio'>
        <MenuItemIcon className='text-primary-400'>
          <IconSettings
            height='20'
            width='20'
            className='text-primary-400 sroke-current fill-current' />
        </MenuItemIcon>
        <MenuItemText className='text-primary-400'>Settings</MenuItemText>
      </MenuItem>
    </div>
  </div>
}