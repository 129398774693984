import * as React from 'react'
import { Route, Switch } from 'react-router'
import { LearningTracks } from 'LearningTracks'
import { TrainingPage } from 'Trainings/TrainingPage'
import { Trainings } from 'Trainings'
import { LearningTrackView } from 'LearningTracks/LearningTrackView'
import { Mentorship } from 'Mentorship'
import { MentorshipView } from 'Mentorship/MentorshipView'
import { OrgMembers } from '../OrgMembers'
import { TrainingSessionPage } from 'Trainings/TrainingSessions/TrainingSessionPage'
import { Studio } from 'Studio'
import { SlideDeckEditor } from 'Trainings/SlideDeckEditor'

export const OrgComponentsRoutes = (props) => {
  return <Switch>
  <Route path='/orgs/:org_id/members' component={OrgMembers} />
  <Route path='/orgs/:org_id/tracks/:trackId' component={LearningTrackView} />
  <Route path='/orgs/:org_id/tracks' exact component={LearningTracks} />
  <Route path='/orgs/:org_id/mentorship/:mentorshipId' component={MentorshipView} />
  <Route path='/orgs/:org_id/mentorship' component={Mentorship} />
  <Route path='/orgs/:org_id/trainings/:training_id' exact component={TrainingPage} />
  <Route path='/orgs/:org_id/trainings' exact component={Trainings} />
  <Route path={`/orgs/:org_id/trainings/:training_id/sessions/:session_id`} component={TrainingSessionPage} />
  <Route path={`/orgs/:org_id/trainings/:training_id/slide_decks/:slide_deck_id`} component={SlideDeckEditor} />
  <Route path={`/orgs/:org_id/studio`} component={Studio} />
</Switch>
}