import { LearningTrackMember } from 'api/models'
import { OutlineButton } from 'components/Buttons'
import * as React from 'react'
import { ReactComponent as IconMore } from 'assets/Icons/chevron.svg'
import { DropDown, DropDownItem } from 'components/DropDownMenu'
import { Avatar } from 'components/Avater'

interface M {
  mentors: LearningTrackMember[]
  selectedMentor?: LearningTrackMember | null,
  onSelectedMentor(mentor:LearningTrackMember): void,
}

export const MentorsSelect:React.FC<M> = ({ mentors, selectedMentor, onSelectedMentor }) => {
  const [isOpen, toggleIsOpen] = React.useState(false)

  const handleSelectMentor = React.useCallback((mentor:LearningTrackMember) => {
    onSelectedMentor(mentor)
    toggleIsOpen(false)
  }, [onSelectedMentor])

  const rows = mentors.map(mentor => {
    return <DropDownItem key={mentor.id} onClick={() => handleSelectMentor(mentor)}>
      <Avatar user={mentor.user} />
      <div className='col-span-3'>{mentor.user.name}</div>
    </DropDownItem>
  })
   const noMentor = <DropDownItem>No mentors listed </DropDownItem>
  return <div>
    <OutlineButton onClick={() => toggleIsOpen(!isOpen)}>
      {selectedMentor ? selectedMentor?.user.name : 'Select Mentor'} <IconMore height='20' />
    </OutlineButton>
    <DropDown isOpen={isOpen} onMouseLeave={() => toggleIsOpen(false)}>
      {rows.length ? rows : noMentor}
    </DropDown>
  </div>
}