import { addLearningTrack } from 'api/learning-tracks'
import { useAuthSelector } from 'auth/auth-reducer'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { LearningTrackProps } from '../model'
import { D, LearningTrackForm } from './LearningTrackForm'

export const AddLearningTrack = ({ afterSubmit }) => {
  const initialValues = {
    name: '',
    description: ''
  }

  const { data: user } = useSelector(useAuthSelector)
  const { org_id } = useParams<{org_id:string}>()

  const validate = (values:D) => {
    const errors = {name: false, description: false}
    if (values.name.trim().length < 6 ){
      errors.name = true
    }
    if (values.description.trim().length < 10) {
      errors.description = true
    }

    return errors
  }

  const handleSubmit = React.useCallback(({ name, description }:D) => {
    if (!(name && description)) return

    const track:LearningTrackProps = {
      name,
      description,
      created_by: {
        id: user.uid,
        name: user.name
      },
      org_id: org_id,
      created_at: new Date()
    }
    addLearningTrack(track)
    .then(afterSubmit)
    .catch(err => console.error(err))
  }, [afterSubmit, org_id, user.name, user.uid])

  return <LearningTrackForm
    initialValues={initialValues}
    validate={validate} 
    onSubmit={handleSubmit} />
}