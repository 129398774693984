import * as React from 'react'
import { NameAvatar } from './NameAvatar'
import { IdName } from 'api/models'

interface T {
  user:IdName
}
export const Avatar:React.FC<T> = ({ user}) => {
  if (user.display_image) {
    return <img src={user.display_image} alt='profile_img' className='rounded-full w-8 h-8' />
  }
  return <NameAvatar name={user.name} />
}