import * as React from 'react'
import { Modal } from 'components/Modal'
import { ModalBody } from 'components/Modal/ModalBody'
import { Tab, TabItem } from 'components/Tabs'
import { ModalHeader } from 'components/Modal/ModalHeader'
import { SlideTextEditor } from './SlideTextEditor'
import { ModalFooter } from 'components/Modal/ModalFooter'
import { Button, OutlineButton } from 'components/Buttons'

export const NewSlideModal = () => {

  return <Modal>
    <ModalHeader title='New Slide' />
    <ModalBody>
      <div className='border-b border-gray-100 mb-6'>
        <Tab className='text-gray-700'>
          <TabItem to=''>Select Type</TabItem>
          <TabItem to='' active>
            Text
          </TabItem>
          <TabItem to=''>Picture</TabItem>
          <TabItem to=''>Video</TabItem>
          <TabItem to=''>Quiz</TabItem>
        </Tab>
      </div>
      
      <SlideTextEditor />
    </ModalBody>
    <ModalFooter>
      <OutlineButton>Cancel</OutlineButton>
      <Button color='primary'>Save Slide</Button>
    </ModalFooter>
  </Modal>
}