import { Message, MessageType } from 'helpers/messages'
import * as React from 'react'

export const MessageDisplay = ({type, text}:Message) => {
  const classes = ['text-sm', 'p-2', 'rounded', 'flex', 'justify-between', 'content-center']
  
  if (type === MessageType.error) {
    classes.push('text-red-800 bg-red-100')
  }

  if (type === MessageType.warning) {
    classes.push('text-yellow-800 bg-yellow-100')
  }

  if (type === MessageType.success) {
    classes.push('text-green-800 bg-green-100')
  }

  return <div className={classes.join(' ')}>
    <span>{text}</span>
    
    {/* */}
  </div>
}