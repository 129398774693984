import * as React from 'react'
import { ReactComponent as IconPerson } from 'assets/Icons/person.svg'
import { ReactComponent as IconCalendar } from 'assets/Icons/calendar.svg'
import { DateFormat } from 'components/Dates'

export const ProjectHeader = ({ project }) => {
  return <div className='p-2 bg-white text-gray-500 flex justify-between'>
    <div>
      <div className='text-xl capitalise text-gray-800'>{ project.name }</div>
      <div className='capitalize mb-2 text-xs'>{ project.type }</div>
    </div>
    <div className='flex justify-between text-sm'>
      <div className='flex'>
        <IconCalendar height='20' />
        <DateFormat date={project.created_at} />
      </div>
      <div className='flex'>
        <IconPerson height='20' className='stroke-current' />
        <div className='capitalize'>{ project.created_by.name }</div>
      </div>
    </div>
  </div>
}