 import * as React from 'react'
 import cn from 'classnames'

 interface InputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  iconLeft?: string
  iconRight?: string
  inline?: boolean,
  hasError?: boolean
 }

export const InputText:React.FC<InputTextProps> = ({
  value,
  onChange = (e) => e,
  onBlur = (e) => e,
  label,
  placeholder,
  name,
  type = 'text',
  iconLeft,
  iconRight,
  inline,
  hasError=false
}) => {
  let leftIcon = ''
  let rightIcon = ''
  if (iconLeft) {
    leftIcon = require(`../assets/Icons/${iconLeft}.svg`);
  }
  if (iconRight) {
    rightIcon = require(`../assets/Icons/${iconRight}.svg`);
  }

  return <div className={cn('max-w-sm md:max-w-lg text-sm', {
    'mb-10': !inline
  })}>
    {label &&
      <label className='text-gray-600 capitalise'>{label}</label>
    }
    <div 
      className={cn('rounded-full border py-2 px-4 flex',
        hasError ? 'border-red-300' : 'border-gray-200'
      )}
    >
      {leftIcon && <img 
        src={leftIcon} 
        width='18' 
        height='18'
        className='max-h-18 max-w-18'
        alt='' />
      }
      <input
        value={value}
        onChange={onChange}
        className='w-full outline-none px-4 text-gray-800' 
        placeholder={placeholder}
        name={name}
        type={type}
        onBlur={onBlur}
      />
      {rightIcon}
    </div>
  </div>
}

export const FormField = ({
  hasError,
  ...props
}) => {
  return <InputText {...props} hasError={hasError} />
}