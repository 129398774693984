import { TrainingProps } from 'api/models'
import { getTraining } from 'api/trainings'
import { BackButton } from 'components/BackButton'
import { BarSpinner } from 'components/Spinners'
import * as React from 'react'
import { useParams } from 'react-router'
import { TrainingSessions } from 'Trainings/TrainingSessions'
import { ReactComponent as IconTraingPlan } from 'assets/Icons/file-plan.svg'
import { OutlineButton } from 'components/Buttons'
import { Colors } from 'helpers/colors'
import { ModalContext } from 'components/Modal'
import { SlideDeckForm } from 'Trainings/SlideDeckList'

export const TrainingPage = () => {
  const { training_id } = useParams<{ training_id: string, org_id }>()
  const { openModal } = React.useContext(ModalContext)
  const [training, setTraining] = React.useState({} as TrainingProps)
  const [loading, toggleLoading] = React.useState(true)

  React.useEffect(() => {
    getTraining(training_id).then(training => setTraining(training)).finally(() => toggleLoading(false))
  }, [training_id])


  if (loading) return <BarSpinner />

  return <React.Fragment>
    <div className='bg-white p-4 mb-2 flex gap-4 justify-between items-center'>
      <BackButton />
      {training.title}
      <div className='flex justify-around'>
        <OutlineButton color={Colors.PRIMARY}>
          <IconTraingPlan height='20' className='stroke-current' />
          Training Plan
        </OutlineButton>
        <OutlineButton color={Colors.PRIMARY}>
          New Session
        </OutlineButton>
        <OutlineButton color={Colors.PRIMARY} onClick={openModal}>
          New SlideDeck
        </OutlineButton>
      </div>
    </div>
    <SlideDeckForm />
    <TrainingSessions training={training} />
  </React.Fragment>
}