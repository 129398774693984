export const getRandomEnumValue = (en) => {
  const values = Object.values(en)
  const randomIndex = Math.floor(Math.random() * values.length)
  return values[randomIndex]
}

export const getRandomString = (len = 6) => {
  return Math.random().toString(36).substring(len);
}

export const verifyEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/
  return re.test(email);
}

export const verifyPassword = (password: string) => {
  return (password.length > 6)
}

export const abortableAsyncCaller = (func) => {
  return () => {
    const controller = new AbortController()
    func()
    return () => controller.abort()
  }
}