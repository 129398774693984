import * as React from 'react'
import { useHistory } from 'react-router'
import { LoginComponent } from './LoginComponent'
import { verifyEmail, verifyPassword } from 'helpers'
import { login } from 'api/auth'

export const Login = () => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [ok, setOk] = React.useState(false)

  const history = useHistory()

  const handleLogin = () => {
    if (!verifyEmail(email)) {
      setMessage('Please supply a valid email')
      setOk(false)
      return
    }
    if (!verifyPassword(password)) {
      setOk(false)
      setMessage('Passwords must be at least 7 characters long')
      return 
    }
    login(email, password)
    .then(res => {
      history.replace('/')
    })
    .catch(error => setMessage(error.message)) 
  }

  return <>
    <LoginComponent 
      email={email}
      password={password}
      ok={ok}
      message={message}
      onEmailChange={(e) => setEmail(e.target.value)}
      onPasswordChange={(e) => setPassword(e.target.value)}
      onLogin={handleLogin}
    />
  </>
}