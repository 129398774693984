import * as React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { LandingPage } from 'landing/Landing'
import { Login } from 'auth/Login'
import { Signup } from 'auth/Signup'
import { ModalContextProvider } from 'components/Modal'
import { Home } from 'Home'

import '../styles/tailwind.css'

interface ViewportSize {
  isMobile: boolean
}

export const LayoutContext = React.createContext<ViewportSize>({ isMobile: false })

export const Layouts = ({ isPrivate }) => {  
  const [isMobile, setIsMobile] = React.useState(false)


  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile((window.innerWidth <= 620))
    })
  }, [isMobile])
   const routes = isPrivate ? <Route component={Home} path='/' /> : 
   <Switch>
      <Route path='/login' component={Login} />
      <Route path='/signup' component={Signup} />
      <Route path='/'>
        <LandingPage />
      </Route>
    </Switch>

  return <ModalContextProvider>
    <Router>
      <LayoutContext.Provider value={{isMobile}}>
        {routes}
      </LayoutContext.Provider>
    </Router>
  </ModalContextProvider>
}