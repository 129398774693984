import { Button } from 'components/Buttons'
import { InputText } from 'components/InputText'
import { Modal, ModalContext } from 'components/Modal'
import { ModalBody } from 'components/Modal/ModalBody'
import { ModalFooter } from 'components/Modal/ModalFooter'
import { ModalHeader } from 'components/Modal/ModalHeader'
import * as React from 'react'
import { useParams } from 'react-router'
import { Cohort } from 'api/models'
import { getLearningTrack, saveCohort } from 'api/learning-tracks'
import { LearningTrackProps } from 'LearningTracks/model'

export const AddCohort = () => {
  const { trackId } = useParams<{trackId: string, org_id: string}>()
  const [cohortName, setCohortName] = React.useState('')
  const [track, setTrack] = React.useState<LearningTrackProps>()

  const { closeModal } = React.useContext(ModalContext)

  React.useEffect(() => {
    getLearningTrack(trackId).then(setTrack)
  }, [trackId])

  const handleSaveCohort = React.useCallback(() => {
    if (!track) {
      console.warn('can\'t save cohort. reason: missing learning track')
      return
    }
    const cohort:Cohort = {
      name: cohortName,
      track: {id: trackId, name: track.name},
      active: true,
      id: ''
    }
    saveCohort(cohort)
    closeModal()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortName, track, trackId])

  return <Modal>
    <div>
      <ModalHeader title='Create New Cohort'></ModalHeader>
      <ModalBody>
        <InputText
          label='Cohort Name/Number'
          placeholder='Cohort 1'
          onChange={(e) => setCohortName(e.target.value)}
          value={cohortName}
        />
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={() => handleSaveCohort()}>Save Cohort</Button>
      </ModalFooter>
    </div>
  </Modal>
}