import { auth } from './firebase'

export const login = async (email:string, password:string) => {
  const response = await auth.signInWithEmailAndPassword(email, password)
  if (response.user) {
    return response.user
  }
  return Promise.reject(response)
}

interface S {
  (
    email: string,
    password:string,
    firstName: string,
    surname: string
  ): Promise<any>
}

export const signUp:S = async ( email, password, firstname, surname ) => {
  try {
    const { user } = await auth.createUserWithEmailAndPassword(email, password)
    await user?.updateProfile({ displayName: [firstname, surname].join(' ') })
    const url = `${window.location.protocol}//${window.location.host}/?email=${email}`
    const actionCode = {
      url
    }
    await user?.sendEmailVerification(actionCode)
    return { error: false }
  } catch (e) {
    console.error(e)
    return Promise.reject({ error: true,  })
  }
}