import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Layouts } from './layouts'
import { auth } from 'api/firebase'
import { AUTH_USER_UPDATE } from 'auth/auth-reducer'
import { SplashScreen } from 'layouts/SplashScreen'
import { getUserOrgs } from 'api/orgs'
import { LOAD_USER_ORGS } from 'orgs/orgs-reducers'

export const Bootstrap = () => {
  const [isLoading, toggleIsLoading] = React.useState(true)
  const [isPrivate, toggleIsPrivate] = React.useState(false)

  const dispatch = useDispatch()

  auth.onAuthStateChanged(user => {
    if (!(user && user.emailVerified)) {
      dispatch({
        type: AUTH_USER_UPDATE,
        data: null
      })

      toggleIsPrivate(false)
    } else {
      dispatch({
        type: AUTH_USER_UPDATE,
        data: { uid: user.uid, email: user.email, emailVerified: user.emailVerified, name: user.displayName }
      })
      getUserOrgs(user.uid, (orgs) => dispatch({type: LOAD_USER_ORGS, data: orgs}))
      toggleIsPrivate(true)
    }
    toggleIsLoading(false)
  })

  if (isLoading) {
    return <SplashScreen />
  }

  return <Layouts isPrivate={isPrivate} />
}
