import * as api from '.'
import { Collections } from './firebase';
import { MentorMentee, Mentorship } from "./models";

export const saveMentorMentee = (mentorship:MentorMentee) => {
  return api.save(Collections.MENTOR_MENTEE, mentorship)
}

interface G {
  (
    mentorId: string,
    handler: (mentorships: MentorMentee[]) => void
  )
}

const castResp = (data, handler) => {
  const docs = data.map(doc => doc as MentorMentee )
  return handler(docs)
}

export const getMenteeByMentorId:G = (mentorId: string, handler) => {
  return api.subscribe(Collections.MENTOR_MENTEE)
    .where('mentor.id', '==', mentorId)
    .onData(resp => castResp(resp, handler))
}

export const getMenteeByLearningTrack = (trackId: string, handler) => {
  return api.subscribe(Collections.MENTOR_MENTEE)
    .where('track.id', '==', trackId)
    .onData(resp => castResp(resp, handler))
}

export const getMentorship = async (id:string) => {
  const mentorship =  await api.get(Collections.MENTOR_MENTEE, id)
  if (!mentorship) {
    throw Error('Could not find record for this mentorship')
  }
  return api.convertFBTimeToDate('start_date', mentorship) as Mentorship
}

export const updateMentorship = async (mentorship:Mentorship) => {
  return  api.update(Collections.MENTOR_MENTEE, mentorship.id, mentorship)
}