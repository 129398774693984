import * as React from 'react'
import { Paper } from 'components/Paper'
import { InputText } from 'components/InputText'
import { Button } from 'components/Buttons'
import { MessageDisplay } from 'components/Message'
import Supervisor from 'assets/Supervisor.jpeg'
import { ReactComponent as IconSend } from 'assets/Icons/send.svg'
import { ReactComponent as IconBack } from 'assets/Icons/arrow-left.svg'
import { Link } from 'react-router-dom'
import { MessageType } from 'helpers/messages'

const LoginComponent = ({
  email,
  password,
  onEmailChange,
  onPasswordChange,
  onLogin,
  ok,
  message
}) => {
  const messageVariant = ok ? MessageType.success : MessageType.error
  return <>
    <Paper>
      <Link to='/' className='text-primary-400 flex'>
        <IconBack height='20' />Back
      </Link>
      <img src={Supervisor} alt='mentor'/>
      {message && <MessageDisplay type={messageVariant} text={message} />}
      <InputText label='Email' value={email} onChange={onEmailChange} iconLeft='email' />
      <InputText label='Password' value={password} onChange={onPasswordChange} type='password' iconLeft='key' />
      <div>Don't have account? <Link to='/signup' className='text-secondary-400'>Signup</Link></div>
      <div className='flex justify-end'>
        <Button fill color='primary' onClick={onLogin}>
          <IconSend className='text-primary-400' height='20'  />
          Login
        </Button>
      </div>
    </Paper>
  </>
}

export { LoginComponent }