import * as React from 'react'
import cn from 'classnames'
import { BarSpinner } from 'components/Spinners'
import { OrgMember } from 'api/models'
import { OutlineButton } from 'components/Buttons'
import { ReactComponent as IconOrgMembers } from 'assets/Icons/crowd.svg'
import { ReactComponent as IconMemberInvite } from 'assets/Icons/mail.svg'
import { NameAvatar } from 'components/Avater/NameAvatar'
import { OrgRolesMenu } from 'orgs/OrgMembers/OrgRolesMenu'

interface P {
  orgMembers: OrgMember[]
  className?: string
  isLoading: boolean
  openModal(): void
}

export const OrgMembersList:React.FC<P> = ({ orgMembers, className, isLoading, openModal }) => {
  let view

  if (isLoading) {
    view = <BarSpinner width={40} />
  } else {
    view = orgMembers.map((member, i) => {
      return <div 
        className={`
        pointer-events-auto grid grid-cols-8
        px-2 py-4 items-center
        text-sm mt-2 border-gray-100 border-b ${member.disabled ? 'text-gray-200': ''}` }
        key={i}>
        <NameAvatar name={member.user.name.charAt(0)} />
        <div className='col-span-4 flex flex-col gap-2'>
          <span className='bold'>{member.user.name}</span>
          <div className='text-xs italic text-gray-600'>{member.active && 'Active'}</div>
        </div>
        <div className='col-span-2 text-xs capitalize'>{member.roles.join(', ')}</div>
        <OrgRolesMenu member={member}  />
      </div>
    })
  }

  return <div className={cn(className, ['bg-white w-full'])}>
    <div className='border-gray-100 border-b p-2 flex items-center justify-between'>
      <div className='flex text-gray-600'>
        <IconOrgMembers height='24' className='stoke-current fill-current mr-2' />
        Members
      </div>
      <div>
        <OutlineButton 
          color='secondary' 
          rounded
          onClick={openModal}
          className='text-sm'>
            <IconMemberInvite className='stroke-current' height='20' />
            Invite Members
        </OutlineButton>
      </div>
    </div>
    {view}
  </div>
}