import * as React from 'react'
import cn from 'classnames'
import { Colors } from 'helpers/colors'
import { OutlineButton } from 'components/Buttons'
import { Icon, IconNames } from 'components/Icon'
import { DropDown, DropDownItem } from 'components/DropDownMenu'
import { ColorPicker } from 'components/ColorPicker'

export const SlideBuilderToolBox = ({
  onBackgroundColorChange,
  onTextColorChange,
  className,
  backgroundColor,
  textColor,
  onBoldEvent,
  onItalicEvent,
  onUnderlineEvent
}) => {
  const [bgColorPockerIsOpen, setBgColorPickerIsOpen] = React.useState(false)
  const [textColorPockerIsOpen, setTextColorPickerIsOpen] = React.useState(false)
  
  const ColorBox = () => <button 
      onClick={() => setBgColorPickerIsOpen(true)}
      className={cn(
        backgroundColor === Colors.WHITE ? 'bg-white' : `bg-${backgroundColor}-800`,
        `w-6 h-6 border border-gray-100`
      )}></button>
  
  const ColorText = () => <button 
      onClick={() => setTextColorPickerIsOpen(true)}
      className={cn(
        textColor === Colors.WHITE ? 'text-whitee' : `text-${textColor}-800`,
        `w-6 h-6 border-b-2 border-${textColor}-100`
      )}><Icon name={IconNames.FORMAT_TEXT_COLOR} /></button>
  
  return <section className={`flex gap-6 items-center bg-white ${className} border-t border-gray-100`}>
    <div>
      <ColorBox />
      <DropDown isOpen={bgColorPockerIsOpen} onMouseLeave={() => setBgColorPickerIsOpen(false)}>
        <DropDownItem><ColorPicker onChange={(c) => onBackgroundColorChange(c)}/></DropDownItem>
      </DropDown>
    </div>
    <div>
      <ColorText />
      <DropDown isOpen={textColorPockerIsOpen} onMouseLeave={() => setTextColorPickerIsOpen(false)}>
        <DropDownItem><ColorPicker onChange={(c) => onTextColorChange(c)}/></DropDownItem>
      </DropDown>
    </div>
    <div className='border-l-2 border-r-2 px-2 border-gray-100 flex'>
      <OutlineButton>
        <Icon name={IconNames.FORMAT_TEXT_SIZE_INCREASE}/>
      </OutlineButton>
      <OutlineButton>
        <Icon name={IconNames.FORMAT_TEXT_SIZE_DECREASE}/>
      </OutlineButton>
      <OutlineButton onClick={onBoldEvent}>
        <Icon name={IconNames.FORMAT_TEXT_BOLD}/>
      </OutlineButton>
      <OutlineButton onClick={onItalicEvent}>
        <Icon name={IconNames.FORMAT_TEXT_ITALIC}/>
      </OutlineButton>
      <OutlineButton onClick={onUnderlineEvent}>
        <Icon name={IconNames.FORMAT_TEXT_UNDERLINE}/>
      </OutlineButton>
    </div>
  </section>
}