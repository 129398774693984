import { Button, OutlineButton } from 'components/Buttons'
import { Modal, ModalContext } from 'components/Modal'
import { ModalBody } from 'components/Modal/ModalBody'
import { ModalFooter } from 'components/Modal/ModalFooter'
import { ModalHeader } from 'components/Modal/ModalHeader'
import * as React from 'react'
import { useParams } from 'react-router'
import  * as api  from 'api'
import { Collections } from 'api/firebase'
import { LearningTrackMember, OrgMember, OrgRoles } from 'api/models'
import { Avatar } from 'components/Avater'
import { addLearningTrackMember, getLearningTrack } from 'api/learning-tracks'
import { LearningTrackProps } from 'LearningTracks/model'

export const LearningTrackAddMentorModal = () => {
  const { trackId, org_id } = useParams<{trackId: string, org_id: string}>()

  const [mentors, setMentors] = React.useState<OrgMember[]>([])
  const [track, setTrack] = React.useState<LearningTrackProps>()

  const { closeModal } = React.useContext(ModalContext)

  React.useEffect(() => {
    api.subscribe(Collections.ORG_MEMBERS).where('org.id', '==', org_id as string).onData((data) => {
      const mentors = data.filter(member => member.roles.includes(OrgRoles.MENTOR))
      setMentors(mentors as OrgMember[])
    })
  }, [org_id])

  React.useEffect(() => {
    getLearningTrack(trackId)
      .then(track => setTrack(track))
      .catch(err => console.error(err))
  }, [trackId])

  const handleAddAsMentor = React.useCallback((mentor:OrgMember) => {
    if (!track) {
      return // Hope it never comes to this else we are screwed
    }
    const member:LearningTrackMember = {
      user: mentor.user,
      track: { id: track.id as string, name: track.name },
      is_active: true,
      role: 'Mentor',
      created_at: new Date(),
      disabled: false,
      id: `${trackId}-${mentor.user.id}`
    }
    addLearningTrackMember(member)
  }, [track, trackId])
  
  const listMentors = () => {
    if (!mentors.length) {
      return <div className='text-gray-600'>Your organisation have not assigned anybody as a Mentor</div>
    }
    return mentors.map((mentor) => {
      return <div className='grid grid-cols-8 text-gray-600 p-4' key={mentor.id}>
        <Avatar user={mentor.user} />
        <div className='col-span-5'>{mentor.user.name}</div>
        <div className='col-span-2'>
          <OutlineButton color='secondary' className='rounded' onClick={() => handleAddAsMentor(mentor)}>Add As Mentor</OutlineButton>
        </div>
      </div>
    })
  }

  return <Modal>
    <div>
      <ModalHeader title='Add Mentors' />
      <ModalBody>
        {listMentors()}
      </ModalBody>
      <ModalFooter><Button onClick={closeModal}>Cancel</Button></ModalFooter>
    </div>
  </Modal>
}