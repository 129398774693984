import * as React from 'react'
import cn from 'classnames'

interface T {
  options: {label: string, value:string}[],
  selectedOption: string
  onSelect: (value:string) => void
  className?: string
  inline?: boolean
  name: string
}

export const RadioButton:React.FC<T> = ({ options, selectedOption, onSelect, className, inline, name}) => {
  return <div className={cn({'flex justify-between': inline})}>
    {
      options.map(opt => {
        return <div className='flex gap-2 items-center py-2' key={opt.value}>
          <input type='radio' value={opt.value} name={name} onChange={(e) => onSelect(e.target.value)} />
          <label className='capitalize'>{opt.label}</label>
        </div>
      })
    }
  </div>
}