import * as React from 'react'
import { TrainingSessionForm } from 'Trainings/TrainingSessions/TrainingSessionForm'
import { TrainSessionList } from 'Trainings/TrainingSessions/TrainingSessionList'
import { TrainingSessionsProps } from 'api/models'
import { fetchTrainingSessions } from 'api/trainings'
import { SlideDeckList } from 'Trainings/SlideDeckList'

export const TrainingSessions = ({ training }) => {
  const [trainingSessions, setTrainingSessions] = React.useState<TrainingSessionsProps[]>([])

  React.useEffect(() => {
    fetchTrainingSessions(training.id, setTrainingSessions)
  }, [training.id])

  return <React.Fragment>
    <div className='grid grid-cols-2 justify-items-stretch gap-2 p-2'>
      <div>
        <TrainingSessionForm training={training} />
        <TrainSessionList trainingSessions={trainingSessions} />
      </div>
      <div>
        <SlideDeckList />
      </div>
    </div>
  </React.Fragment>
}