import * as React from 'react'
import { OutlineButton } from 'components/Buttons'
import { LearningTrackFormModal } from '../LearningTrackFormModal'
import { ModalContext } from 'components/Modal'
import { ReactComponent as IconTrackAdd } from 'assets/Icons/add.svg'

export const LearningTrackListToolbar = () => {
  const { openModal, closeModal } = React.useContext(ModalContext)
  return <div className='p-2 flex justify-end'>
    <div>
      <OutlineButton 
        className='rounded-full' 
        onClick={openModal}
        color='primary'>
          <IconTrackAdd className='stroke-current' height='20' />
          Add LearningTrack
      </OutlineButton>
    </div>
    <LearningTrackFormModal closeModal={closeModal} />
  </div>
}