import * as React from 'react'
import cn from 'classnames'
import { Colors } from 'helpers/colors'

export const ColorPicker = ({ onChange }) => {
  const colors:Colors[] = Object.values(Colors)

  const colorBoxes = colors.map(c => 
    <button 
      key={c}
      onClick={() => onChange(c)}
      className={cn(
        c === Colors.WHITE ? 'bg-white' : `bg-${c}-800`,
        `w-6 h-6 border border-gray-100`
      )}></button>
  )
  return <div className='grid grid-cols-4 gap-2 p-4 border-b border-gray-100'>{colorBoxes}</div>
}