import * as React from 'react'
import { format, formatDistance } from 'date-fns'

interface P {
  date: Date
  dateFormat?: string
}

export const DateFormat: React.FC<P> = ({ date, dateFormat ='d MMM, yyyy' }: P) => {
  return <div>{format(date, dateFormat)}</div>
}

interface T {
  from?: Date
  to?: Date
}
export const DateDiff: React.FC<T> = ({ from, to }) => {
  const now = new Date()
  if (!from && !to){
    console.error('At least one of "from" and "to" dates has to be a valid date')
  }
  if (!from) {
    from = now
  }
  if (!to) {
    to = now
  }
  return <React.Fragment>{formatDistance(from, to)}</React.Fragment>
}