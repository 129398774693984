import { SlideDeck } from 'api/models'
import { Button, OutlineButton } from 'components/Buttons'
import { InputText } from 'components/InputText'
import { Modal, ModalContext } from 'components/Modal'
import { ModalBody } from 'components/Modal/ModalBody'
import { ModalFooter } from 'components/Modal/ModalFooter'
import { ModalHeader } from 'components/Modal/ModalHeader'
import { Colors } from 'helpers/colors'
import * as React from 'react'
import { useParams } from 'react-router'
import * as Slides from 'api/slide'
import { Link } from 'react-router-dom'

export const SlideDeckForm = () => {
  const { org_id, training_id } = useParams<{org_id:string, training_id: string}>()
  const { closeModal } = React.useContext(ModalContext)

  const [deck, updateDeck] = React.useState<Omit<SlideDeck, 'id'>>({
    title: '',
    org_id,
    training_id,
    length: 0,
    count: 0
  })

  const handleSaveDeck = () => {
    Slides.saveSlideDeck(deck).then(closeModal)
  }

  return <Modal>
    <ModalHeader title='New Slide Deck' />
    <ModalBody>
      <InputText label='Title' value={deck?.title} onChange={(e) => updateDeck({...deck, title: e.target.value})} />
    </ModalBody>
    <ModalFooter>
      <OutlineButton onClick={closeModal}>Cancel</OutlineButton>
      <Button color={Colors.TEAL} onClick={() => handleSaveDeck()}>Create Slide Deck</Button>
    </ModalFooter>
  </Modal>
}

export const SlideDeckList = () => {
  const { org_id, training_id } = useParams<{org_id:string, training_id: string}>()
  const [decks, setDecks] = React.useState<SlideDeck[]>([])

  React.useEffect(() => {
    Slides.getSlideDecks(org_id, training_id, setDecks)
  }, [org_id, training_id])

  return <div className='bg-white p-2'>
    <div className='text-gray-700 text-xl border-b border-gray-100 mb-6'>Slide Decks</div>
    {decks.map(deck => <div className='p-2 border-b border-gray-100 grid grid-cols-4' key={deck.id}>
      <Link to={`/orgs/${org_id}/trainings/${training_id}/slide_decks/${deck.id}`} className='text-secondary-700 col-span-2'>
        {deck.title}
      </Link>
      <span>{deck.count} Slides</span>
      <span>{deck.length} Minutes</span>
    </div>)}
  </div>
}