import { TrainingProps } from 'api/models'
import { fetchTrainings } from 'api/trainings'
import * as React from 'react'
import { useParams } from 'react-router'
import { TrainingList } from './List'

export const Trainings = () => {
  const { org_id } = useParams<{org_id:string}>()
  const [trainings, setTrainings] = React.useState<TrainingProps[]>([])

  React.useEffect(() => {
    const unsubscribe = fetchTrainings(org_id, setTrainings)
    return () => unsubscribe()
  }, [org_id])

  return <TrainingList trainings={trainings} />
}