import * as React from 'react'
import { Message } from 'helpers/messages'
import { MessageDisplay } from 'components/Message'
import { ProjectMembers } from './ProjectMembers'
import { Route, Switch } from 'react-router'
import { ProjectHeader } from './ProjectHeader'
import { LearningTracks } from '../LearningTracks'
import { ProjectMenu } from './ProjectMenu'
import { Trainings } from '../Trainings'
import { TrainingPage } from '../Trainings/TrainingPage'
import { ProjectProps } from 'api/models'

interface P {
  project: ProjectProps
  message?: Message
}

export const ProjectDetail: React.FC<P> = ({ project, message }) => {
  return <div className='h-screen bg-gray-100 md:flex'>
    <ProjectMenu org_id={project.org_id} project_id={project.id} />
    <div className='sm:w-3/4 md:w-5/6 p-2'>
      <ProjectHeader project={project} />
      {message && <MessageDisplay text={message.text} type={message.type} /> }
      <Switch>
        <Route path='/project/:project_id/tracks/:track_id' exact component={LearningTracks} />
        <Route path='/project/:project_id/tracks' exact component={LearningTracks} />
        <Route path='/project/:project_id/trainings/:training_id' exact component={TrainingPage} />
        <Route path='/project/:project_id/trainings' exact component={Trainings} />
        <Route path='/project/:project_id/members' component={ProjectMembers} />
        <Route path='/' exact render={() => <ProjectDetail project={project} message={message} />} />
      </Switch>
    </div>
  </div>
} 