import * as React from 'react'
import grunge from './assets/orange-grunge-bg.jpg'

const styles = {
  primary: {
    backgroundImage: `url(${grunge})`,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent'
  },
  white: {
    color: '#FFF'
  }
}

export const Siftora = ({ className, color = '' }) => {
  return <div style={styles[color]} className={className}>
    <div>Siftora</div>
  </div>
}