
import { Mentorship } from 'api/models'
import { abortableAsyncCaller } from 'helpers'
import * as React from 'react'
import { useParams } from 'react-router'

import { CircleSpinner } from 'components/Spinners'
import { getMentorship, updateMentorship } from 'api/mentorship'

import { MentorshipLayout } from './MentorshipLayout'
import { differenceInCalendarMonths } from 'date-fns/esm'
import { isValid } from 'date-fns'

export const MentorshipView = (props) => {
  const { mentorshipId } = useParams<{mentorshipId: string}>()

  const [mentorship, setMentorship] = React.useState<Mentorship>({} as Mentorship)
  const [loading, toggleLoading] = React.useState(true)
  const [progress, setProgress] = React.useState<number>(0)

  React.useEffect(abortableAsyncCaller(() => {
    const controller = new AbortController()    
    toggleLoading(true)
    getMentorship(mentorshipId).then(setMentorship)
      .catch(err => {
        console.error(err)
      })
      .finally(() => toggleLoading(false))
    return () => controller.abort()
  }), [mentorshipId])

  React.useEffect(() => {
    if (!(isValid(mentorship.start_date) && mentorship.period)) return
    const monthsSince = differenceInCalendarMonths(new Date(), mentorship.start_date)
    const progress = Math.round((100 / mentorship.period) * monthsSince)
    setProgress(progress)
  }, [mentorship])

  const handleStartDateChange = (date) => {
    mentorship.start_date = new Date(date)
    setMentorship(mentorship)
  }

  const handleDurationChange = (duration) => {
    setMentorship({...mentorship, period: parseInt(duration)})
  }

  const handleUpdateMentorshipRecord = () => {
    updateMentorship(mentorship)
  }

  if (loading) {
    return <CircleSpinner />
  }

  return <MentorshipLayout 
    mentorship={mentorship}
    handleDurationChange={handleDurationChange}
    handleStartDateChange={handleStartDateChange}
    handleUpdateMentorshipRecord={handleUpdateMentorshipRecord}
    progress={progress}
  />
}