import * as React from 'react'

export enum IconNames {
  CloseX = 'CloseX', //Backward comp
  CLOSE = 'CloseX',
  NEW_TEXT = 'newText',
  FORMAT_TEXT_BOLD = 'formatTextBold',
  FORMAT_TEXT_SIZE_INCREASE = 'formatTextSizeIncrease',
  FORMAT_TEXT_SIZE_DECREASE = 'formatTextSizeDecrease',
  FORMAT_TEXT_COLOR = 'formatTextColor',
  FORMAT_TEXT_ITALIC = 'formatTextItalic',
  FORMAT_TEXT_UNDERLINE = 'formatTextUnderline'
}

type IconOptions = {
  width?: string,
  height?: string,
  stroke?: string,
  fill?: string
}

const getIcon = (name:string, className:string) => {
  const width = '24px'
  const height = '24px'

  switch(name) {
    case IconNames.CloseX:
      return <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="0.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" />
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>

    case IconNames.CLOSE:
      return <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{width, height}}
      viewBox="0 0 24 24"
      strokeWidth="0.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" />
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>

    case IconNames.FORMAT_TEXT_BOLD:
      return <svg style={{width, height}} viewBox="0 0 24 24" className={className}>
        <path fill="currentColor" d="M13.5,15.5H10V12.5H13.5A1.5,1.5 0 0,1 15,14A1.5,1.5 0 0,1 13.5,15.5M10,6.5H13A1.5,1.5 0 0,1 14.5,8A1.5,1.5 0 0,1 13,9.5H10M15.6,10.79C16.57,10.11 17.25,9 17.25,8C17.25,5.74 15.5,4 13.25,4H7V18H14.04C16.14,18 17.75,16.3 17.75,14.21C17.75,12.69 16.89,11.39 15.6,10.79Z" />
      </svg>

    case IconNames.FORMAT_TEXT_COLOR:
      return <svg style={{width, height}} viewBox="0 0 24 24" className={className}>
        <path fill="currentColor" d="M9.62,12L12,5.67L14.37,12M11,3L5.5,17H7.75L8.87,14H15.12L16.25,17H18.5L13,3H11Z" />
      </svg>

    case IconNames.FORMAT_TEXT_SIZE_INCREASE:
      return <svg style={{width, height}} viewBox="0 0 24 24">
        <path fill="currentColor" d="M5.12,14L7.5,7.67L9.87,14M6.5,5L1,19H3.25L4.37,16H10.62L11.75,19H14L8.5,5H6.5M18,7L13,12.07L14.41,13.5L17,10.9V17H19V10.9L21.59,13.5L23,12.07L18,7Z" />
      </svg>

    case IconNames.FORMAT_TEXT_SIZE_DECREASE:
      return <svg style={{ width, height }} viewBox="0 0 24 24" className={className}>
        <path fill="currentColor" d="M5.12,14L7.5,7.67L9.87,14M6.5,5L1,19H3.25L4.37,16H10.62L11.75,19H14L8.5,5H6.5M18,17L23,11.93L21.59,10.5L19,13.1V7H17V13.1L14.41,10.5L13,11.93L18,17Z" />
      </svg>
    
    case IconNames.FORMAT_TEXT_ITALIC:
      return <svg style={{width, height}} viewBox="0 0 24 24">
          <path fill="currentColor" d="M10,4V7H12.21L8.79,15H6V18H14V15H11.79L15.21,7H18V4H10Z" />
      </svg>

    case IconNames.FORMAT_TEXT_UNDERLINE:
      return <svg style={{width, height}} viewBox="0 0 24 24">
        <path fill="currentColor" d="M5,21H19V19H5V21M12,17A6,6 0 0,0 18,11V3H15.5V11A3.5,3.5 0 0,1 12,14.5A3.5,3.5 0 0,1 8.5,11V3H6V11A6,6 0 0,0 12,17Z" />
      </svg>

    default:
      return null
  }
}
interface I extends IconOptions {
  name: string,
  className?: string
}

export const Icon: React.FC<I> = ({
  name,
  className=''
}) => {
  const Icon = () => getIcon(name, className)
  return <Icon />
}