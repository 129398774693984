import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OrgMember } from 'api/models'
import { SplashScreen } from 'layouts/SplashScreen'
import { CHANGE_USER_ACTIVE_ORG, useGetDefaultActiveOrg, useUserOrgs } from '../orgs/orgs-reducers'
import { Route } from 'react-router'
import { ProjectRoutes } from 'projects/ProjectRoutes'
import { OrgHome } from 'orgs/OrgDetails'

export const Home = () => {
  const [orgs, setOrgs] = React.useState([] as OrgMember[])
  
  const [activeOrg, setActiveOrg] = React.useState<OrgMember>()
  const [loading, toggleLoading] = React.useState(true)

  const dispatch =  useDispatch()

  
  const activeUserOrg = useSelector(useGetDefaultActiveOrg)
  const userOrgs = useSelector(useUserOrgs)

  React.useEffect(() => {
    setOrgs(userOrgs)
  }, [userOrgs])

  React.useEffect(() => {
    if (activeOrg || !orgs.length) return
    dispatch({type: CHANGE_USER_ACTIVE_ORG, data: orgs[0]})
  }, [activeOrg, dispatch, orgs])

  React.useEffect(() => {
    if(!activeUserOrg.org) return
    setActiveOrg(activeUserOrg)
    toggleLoading(false)
  }, [activeUserOrg])

  
  if (loading) {
    return <SplashScreen />
  }

  return <div className='bg-gray-100 h-screen text-gray-700 text-sm'>
    <Route path='/orgs/:org_id?' component={OrgHome} />
    <Route path='/project' component={ProjectRoutes} />
    <Route path='/' exact component={OrgHome} />
  </div>
}