import * as React from 'react'
import { useHistory } from 'react-router'
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow-left.svg'
import { Link } from 'react-router-dom'

interface S {
  size?: 'sm' | 'md' | 'lg'
  className?: string
} 

export const BackButton:React.FC<S> = ({ size='sm' }) => {
  const history = useHistory()
  let measure
  const goBack = React.useCallback(() => history.goBack(), [history])

  switch (size) {
    case 'md':
      measure = 20
      break
    case 'lg':
      measure = 24
      break
    default:
      measure = 16
  }
  
  return <Link to='' onClick={goBack} className='flex items-center gap-0'>
    <ArrowIcon height={measure} /> Back
  </Link>
}