import * as React from 'react'
import { Link } from 'react-router-dom'

interface T {
  key: string
  cols: string
  gap?: string
  link?: string
}

export const ListRow:React.FC<T> = ({ children, cols, gap, link }) => {
  const styles = `grid grid-cols-${cols} gap-${gap} text-gray-600 p-4 border-t border-gray-100 items-center`
  if (link) {
    return <Link to={link} className={styles}>{children}</Link>
  }
  return <div className={styles}>
    { children }
  </div>
}