import * as React from 'react'
import { Paper } from '../../components/Paper'
import { FormField } from '../../components/InputText'
import { Button } from '../../components/Buttons'
import { MessageDisplay } from '../../components/Message'
import Mentor from 'assets/Mentor.jpeg'
import { ReactComponent as IconSend } from 'assets/Icons/send.svg'
import { ReactComponent as IconBack } from 'assets/Icons/arrow-left.svg'
import { Link } from 'react-router-dom'
import { Message } from 'helpers/messages'
import { useFormik } from 'formik'
import { User } from 'auth/auth-model'

type InputChangeHandler = (v: string) => void

interface D extends User {
  confirmPassword: string
  firstname: string
  surname: string
}
interface P {
  initialValues: D
  message?: Message
  validate: (values:D) => {}
  onSubmit(e): void
}

export const SignupForm:React.FC<P> = (
  {
    initialValues,
    onSubmit,
    message,
    validate
  }) => {
    const formik = useFormik({
      initialValues,
      onSubmit,
      validate 
    })
    const ok = (formik.isValid && formik.dirty)
    return <Paper>
    <Link to='/' className='text-primary-400 flex'>
      <IconBack height='20' />Back
      </Link>
    <img src={Mentor} alt='mentor' />
    {message && <MessageDisplay type={message.type} text={message.text} />}
    <form onSubmit= {formik.handleSubmit}>
      <div className='md:flex'>
        <FormField
          name='firstname'
          iconLeft='person' 
          label='Firstname'
          hasError={formik.errors.firstname}
          onChange={formik.handleChange}
        />
        <FormField
          name='surname'
          iconLeft='person' 
          label='Surname'
          hasError={formik.errors.surname}
          onChange={formik.handleChange}
        />
      </div>
      <FormField 
        iconLeft='email' 
        label='Email'
        hasError={formik.errors.email}
        name='email'
        onChange={formik.handleChange}
      />
      
      <FormField
        iconLeft='key'
        label='Confirm Password'
        type='password'
        name='password'
        hasError={formik.errors.password}
        onChange={formik.handleChange}
      />
      
      <FormField
        iconLeft='key'
        label='Confirm Password'
        type='password'
        name='confirmPassword'
        hasError={formik.errors.confirmPassword}
        onChange={formik.handleChange}
      />

      <div className='flex justify-between items-center'>
        <div>Already have account? <Link to='/login' className='text-secondary-400'>Login</Link></div>
        <Button onClick={onSubmit} disabled={!ok} raised color='primary' type='button'>
          <IconSend height='20' stroke='white' />
          Sign Up
        </Button>
      </div>
    </form>
  </Paper>
}
