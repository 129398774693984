import { getRandomEnumValue } from 'helpers'
import { Colors } from 'helpers/colors'
import * as React from 'react'


interface P {
  name: string
}

export const NameAvatar:React.FC<P> = ({ name }) => {
  const color = getRandomEnumValue(Colors)
  return <div className={`rounded-full bg-${color}-400 text-white h-7 w-7 flex items-center justify-center text-xs`}>
    {name.substr(0,2).toUpperCase()}
  </div>
}