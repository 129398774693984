import { fetchLearningTracks } from 'api/learning-tracks'
import * as React from 'react'
import { useParams } from 'react-router'
import { LearningTrackList } from './List'
import { LearningTrackProps } from './model'

export const LearningTracks = () => {
  const { org_id } = useParams<{org_id:string}>()
  const [tracks, setTracks] = React.useState<LearningTrackProps[]>([])

  React.useEffect(() => {
    const unsuscribe = fetchLearningTracks(org_id, setTracks)
    return () => unsuscribe()
  }, [org_id])

  return <LearningTrackList tracks={tracks} />
}