import * as React from 'react'

export type SelectOption = {label: string, value: string}

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  iconLeft?: string
  iconRight?: string
  options: SelectOption[]
 }

export const Select:React.FC<SelectProps> = ({
  options,
  label,
  iconLeft,
  iconRight,
  onChange = (e) => e,
  value = ''
}) => {
  let leftIcon = ''
  let rightIcon = ''

  if (iconLeft) {
    leftIcon = require(`../assets/Icons/${iconLeft}.svg`);
  }
  if (iconRight) {
    rightIcon = require(`../assets/Icons/${iconRight}.svg`);
  }

  return <div className='max-w-sm mb-10 md:max-w-lg'>
    {label &&
      <label className=' capitalise'>{label}</label>
    }
    <div className='rounded-full border border-gray-200 py-2 px-4 flex'>
      {<img src={leftIcon} width='20' alt='' />}
      <select
        value={value}
        onChange={onChange}
        className='border-transparent w-full outline-none px-4 text-gray-800'>
          <option></option>
          {options.map(opt => 
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          )}
      </select>
      { rightIcon }
    </div>
  </div>
}