import { DropDown, DropDownItem } from 'components/DropDownMenu'
import * as React from 'react'
import { OrgMember, OrgRoles } from 'api/models'
import { ReactComponent as IconCheck } from 'assets/Icons/check.svg'
import { ReactComponent as IconDisable } from 'assets/Icons/remove-circle.svg'
import { ReactComponent as IconEnable } from 'assets/Icons/account/settings.svg'
import { ReactComponent as IconMore } from 'assets/Icons/dots-vertical.svg'
import { toggleMembershipStatus, updateOrgMemberRoles } from 'api/orgs'

interface T {
  member: OrgMember
}

const DisableBtn: React.FC<T> = ({ member }) => {
  const label = member.disabled ? 'Enable' : 'Disable'
  const Icon = member.disabled ? IconEnable : IconDisable

  const disableMember = React.useCallback(toggleMembershipStatus, [member])

  return <DropDownItem onClick={() => disableMember(member)}>
    <Icon height='16' /> {label} 
  </DropDownItem>
}

export const OrgRolesMenu: React.FC<T> = ({ member }) => {
  const [isOpen, toggleIsOpen] = React.useState(false)

  const roles = Object.values(OrgRoles).map(k => {
    const role = k 
    const active = member.roles.includes(role) ? true : false
    return <DropDownItem onClick={() => updateOrgMemberRoles(role, member) } key={k}>
      <IconCheck height='16' className={`stroke-current ${active? 'text-green-600': 'text-gray-100'}`}/>
      <span className='capitalize'>{k}</span>
    </DropDownItem>
  })

  return <div className='flex flex-col'>
    <div
      className='rounded-full hover:bg-gray-100 h-8 w-8 flex items-center' onClick={() => toggleIsOpen(!isOpen)}>
      <IconMore height='16' />
    </div>
    <DropDown isOpen={isOpen}>
      {!member.disabled && roles}
      <DisableBtn member={member} />
    </DropDown>
  </div>
}