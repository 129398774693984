import { Tab, TabItem } from 'components/Tabs'
import * as React from 'react'
import { ReactComponent as IconMembers } from 'assets/Icons/users.svg'
import { ReactComponent as IconCalendar } from 'assets/Icons/calendar.svg'
import { ReactComponent as IconContent } from 'assets/Icons/files.svg'
import { ReactComponent as IconActivity } from 'assets/Icons/activity.svg'
import { BackButton } from 'components/BackButton'
import { Route } from 'react-router'

export const TrainingSessionMenu = ({ trainingTitle }) => {
  
  const tabItemIds = [
    'training_members',
    'training_schedule'
  ]

  const [activeTab, setActive] = React.useState(tabItemIds[0])

  return <React.Fragment>
    <div className='grid grid-cols-6 items-center border-b border-gray-50 border-t'>
      <BackButton />
      <div className='p-2 col-span-2 text-sm'>
        {trainingTitle}
      </div>
      <Tab className=' text-sm col-span-3'>
        <TabItem to='' active={activeTab === 'training_members'}>
          <IconMembers height='20' className='stroke-current' />
          Members
        </TabItem>
        <TabItem to='' active={activeTab === 'training_schedule'}>
          <IconCalendar height='20' className='stroke-current' />
          Schedule
        </TabItem>
        <TabItem to='' >
          <IconActivity height='20' className='stroke-current' />
          Performance
        </TabItem>
      </Tab>
    </div>
    <Route render={() => <p>Routes here</p>} />
  </React.Fragment>
}