import { OrgMember } from "api/models"
import { createSelector } from "reselect"

export const LOAD_USER_ORGS = 'org_members/LOAD_USER_ORGS'
export const CHANGE_USER_ACTIVE_ORG = 'active_org/CHANGE'

let initialState: OrgMember[] = []

export const userOrgsReducer = (state = initialState, action) => {
  switch(action.type) {
    case LOAD_USER_ORGS:
      return [...action.data]
    default:
      return [...state]
  }
}

export const activeUserOrg = (state:OrgMember|null, action:{type: string, data?: OrgMember}) => {
  switch (action.type) {
    case CHANGE_USER_ACTIVE_ORG:
      return {...action.data}
    default:
      return { ...state }
  }
}

const getUserOrgs = (state) => state.user_orgs

export const useUserOrgs = createSelector(
  [getUserOrgs],
  (userOrgs) => userOrgs
)

const getActiveOrg = (state) => state.active_org

export const useGetDefaultActiveOrg = createSelector(
  [getActiveOrg],
  (activeOrg) => activeOrg
)

export const useOrgs = createSelector(
  [getUserOrgs],
  (orgs) => orgs
)
