import { getLearningTrack, getLearningTrackMembers } from 'api/learning-tracks'
import { LearningTrackMember } from 'api/models'
import { ModalContext } from 'components/Modal'
import { LearningTrackProps } from 'LearningTracks/model'
import * as React from 'react'
import { useParams } from 'react-router'
import { LearningTracksCohorts } from './LearningTrackCohorts'
import { AddCohort } from './LearningTrackCohorts/AddCohort'
import { AddCohortLearners } from './LearningTrackCohorts/CohortLearners/AddCohortLearner.tsx'
import { LearningTrackMentors } from './LearningTrackMentors'
import { LearningTrackAddMentorModal } from './LearningTrackMentors/LearningTrackAddMentorModal'
import { LearningTrackSupervisors } from './LearningTrackSupervisor'


interface T {
  mentors: LearningTrackMember[]
  learners: LearningTrackMember[]
}

interface M {
  modal: 'Mentor' | 'Cohort'| 'Learner'
  props?: {[key : string]: any}
}
export const LearningTrackView = () => {
  const { trackId } = useParams<{trackId: string}>()

  const [track, setTrack] = React.useState<LearningTrackProps>()
  const [members, setMembers] = React.useState<LearningTrackMember[]>([])
  const [mentors, setMentors] = React.useState<LearningTrackMember[]>([])
  const [learners, setLearners] = React.useState<LearningTrackMember[]>([])

  const [activeModal, setActiveModal] = React.useState<'Mentor' | 'Cohort'| 'Learner'>('Mentor')
  const [modalProps, setModalProps] = React.useState({} as any)

  const { openModal } = React.useContext(ModalContext)

  React.useEffect(() => {
    getLearningTrack(trackId)
      .then(setTrack)
  }, [trackId])

  React.useEffect(() => {
    getLearningTrackMembers(trackId, setMembers)
  }, [trackId])

  React.useEffect(() => {
    const reducerOutput:T = {mentors: [], learners: []}

    const { mentors, learners }:T = members.reduce((output, member) => {
      switch(member.role) {
        case 'Mentor':
          output.mentors.push(member)
          break
        default:
          output.learners.push(member)
      }

      return output
    }, reducerOutput)
    setMentors(mentors)
    setLearners(learners)
  }, [members])

  const handleOpenModal = React.useCallback(
    (modal: 'Mentor' | 'Cohort'| 'Learner', 
    props?: {[key : string]: any}
    ) => {
    setActiveModal(modal)
    if (props) {
      setModalProps(props)
    }
    openModal()
  }, [openModal])

  return <React.Fragment>
    {activeModal === 'Mentor' && <LearningTrackAddMentorModal />}
    {activeModal === 'Cohort' && <AddCohort />}
    {activeModal === 'Learner' && <AddCohortLearners  {...modalProps}  />}
    <div className='grid grid-cols-2 gap-2 '>
      <div className='grid grid-rows-2 gap-2 text-sm'>
        <LearningTrackMentors mentors={mentors} onOpenModal={() => handleOpenModal('Mentor')} />
        <LearningTrackSupervisors track={track} />
      </div>
      <div className='bg-white'>
        <LearningTracksCohorts onOpenModal={(modal, props) => handleOpenModal(modal, props)} learners={learners} />
      </div>
    </div> 
  </React.Fragment>
}