import * as React from 'react'
import { useSelector } from 'react-redux'
import { useAuthSelector } from 'auth/auth-reducer'
import { Button } from 'components/Buttons'
import { ReactComponent as IconLogout }  from 'assets/Icons/account/logout.svg'
import { auth } from 'api/firebase'
import cn from 'classnames'
import { useHistory } from 'react-router'

export const Logout = ({ className = '' }) => {
  const account = useSelector(useAuthSelector)

  const history = useHistory()

  const handleLogout = async() => {
    await auth.signOut()
    history.replace('/')
  }

  if (!account) return null
  return <Button className={cn(className)} onClick={handleLogout}>
    <IconLogout className='stroke-current' />
    <div className='ml-4'>Sign Out</div>
  </Button>
}