import * as React from 'react'
import { ReactComponent as AddMentor } from 'assets/Icons/user-add.svg'
import { OutlineButton } from 'components/Buttons'

export const LearningTrackSupervisors = ({track}) => {
  return <div className='bg-white p-2'>
    <div className='flex gap-2 justify-between'>
      <h3>Supervisors</h3>
      <OutlineButton color='secondary' className='rounded-full' rounded><AddMentor height='20'/></OutlineButton>
    </div>
  </div>
}