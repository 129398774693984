import React from 'react';
import { Provider } from'react-redux'
import { ErrorBoundry } from './ErrorBoundry';
import { Bootstrap } from './Bootstrap'

import { store as reduxStore } from './redux-store'



function App() {
  return (
    <ErrorBoundry>
      <Provider store={reduxStore}>
        <Bootstrap />
      </Provider>
    </ErrorBoundry>
  )
}

export default App
