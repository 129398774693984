import { SlideDeck } from "./models"
import * as Api from '.'
import { Collections } from "./firebase"

export const getSlideDeck = (slide_deck_id:string) => {

}

export const saveSlideDeck = (deck:Omit<SlideDeck, 'id'>) => {
  return Api.save(Collections.SLIDEDECKS, deck)
}

export const getSlideDecks = (org_id: string, training_id: string, handler) => {
  return Api.subscribe(Collections.SLIDEDECKS)
    .where('org_id', '==', org_id)
    .where('training_id', '==', training_id)
    .onData((response) => {
      handler(response as SlideDeck[])
    })
}