import { createSelector } from 'reselect'

export const AUTH_ERROR_EMAIL = 'auth/EMAIL_ERROR'
export const AUTH_ERROR_PASSWORD = 'auth/PASSWORD_ERROR'
export const AUTH_ERROR_SERVER = 'auth/SERVER_ERROR'
const AUTH_SUCCESS_LOGIN = 'auth/LOGIN_SUCCESS'

export const AUTH_USER_UPDATE = 'auth/USER_UPDATED'
export const AUTH_USER_SIGNED_OUT = 'auth/USER_SIGNED_OUT'

const initialState = {
  ok: false,
  message: '',
  loading: false,
  data: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_ERROR_EMAIL:
    case AUTH_ERROR_PASSWORD:
    case AUTH_ERROR_SERVER:
      return {
        ...state,
        ok: false,
        message: action.message
      }
    case AUTH_SUCCESS_LOGIN:
      return {
        ...state,
        ok: true,
        message: 'login was successful'
      }
    case AUTH_USER_UPDATE:
      return {
        ...state,
        data: {...state.data, ...action.data}
      }
    case AUTH_USER_SIGNED_OUT:
      return {
        ...state,
        data: null
      }
    default:
      return { ...state }
  }
}

// Selectors

const getAuth = (state) => state.auth
export const useAuthSelector = createSelector(
  [getAuth],
  (auth) => auth
)