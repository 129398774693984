import { LearningTrackMember, MentorMentee } from 'api/models'
import { Avatar } from 'components/Avater'
import * as React from 'react'
import { MentorsSelect } from 'Mentorship/MentorsSelect'
import { saveMentorMentee } from 'api/mentorship'
import { LearningTrackProps } from 'LearningTracks/model'

interface T {
  learners: LearningTrackMember[],
  mentors: LearningTrackMember[],
  track: LearningTrackProps,
  mentees: MentorMentee[]
}
export const LearnersList:React.FC<T> = ({ learners, mentors, track, mentees }) => {
  let unpairedLearners:LearningTrackMember[]

  const handleMentorMenteePeering = React.useCallback((mentor:LearningTrackMember, learner:LearningTrackMember) => {
    const mentorship:MentorMentee = {
      mentor: {id: mentor.id, name: mentor.user.name, display_image: mentor.user.display_image || ''},
      mentee: {...learner.user},
      track: { id: track.id as string, name: track.name },
      id: '',
      start_date: null,
      period: 0,
      org_id: track.org_id
    }
    saveMentorMentee(mentorship)
  }, [track])

  if (!mentees.length) {
    unpairedLearners = [...learners]
  } else {
    unpairedLearners = learners
    .filter(learner => {
      const r = mentees.find(mentorship => {
        return mentorship.mentee.id === learner.user.id
      })
      return !r
    })
  }

  const rows = unpairedLearners
    .map(learner => {
    return <div className='grid grid-cols-5 gap-2 p-2 border-b border-gray-100 items-center' key={learner.id}>
      <Avatar user={learner.user} />
      <div className='col-span-2'>{learner.user.name}</div>
      <div className='col-span-2'><MentorsSelect mentors={mentors} onSelectedMentor={(mentor) => handleMentorMenteePeering(mentor, learner)} /></div>
    </div>
  })
  return <div className='bg-white'>
    <div className='border-b-2 border-gray-100 p-2 '>Learners</div>
    {rows}
  </div>
}