export enum Colors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  YELLOW = 'yellow',
  PURPLE = 'purple',
  PINK = 'pink',
  GREEN = 'green',
  GRAY = 'gray',
  INDIGO = 'indigo',
  DEEP_PURPLE = 'deep_purple',
  CYAN = 'cyan',
  TEAL = 'teal',
  AMBER = 'amber',
  WHITE = 'white'
}