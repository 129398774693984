import { OutlineButton } from 'components/Buttons'
import * as React from 'react'
import { ReactComponent as IconTrackAdd } from 'assets/Icons/add.svg'
import { ReactComponent as IconGoTo } from 'assets/Icons/arrow-left.svg'
import { ListRow } from 'components/List'
import { TrainingProps } from 'api/models'
import { NewTrainingModal } from '../NewTraining/NewTrainingModal'
import { ModalContext } from 'components/Modal'

interface T {
  trainings: TrainingProps[]
}

export const TrainingList:React.FC<T> = ({ trainings = [] }) => {
  const { openModal, closeModal } = React.useContext(ModalContext)
  let view
  view = <div className='text-sm text-gray-600 text-center'>No Trainings found for this project. Please add one above</div>

  if (trainings.length) {
    view = trainings.map(training => {
      return <ListRow cols='12' key={training.id} gap='4' link={`/orgs/${training.org.id}/trainings/${training.id}`}>
        <div className='col-span-4 '>
          {training.title}
          <div className='italic capitalise text-gray-400'>
            {training.learning_track.name}
          </div>
        </div>
        <div className='col-span-6'>{training.description}</div>
        <div>{training.active ? 'Active' : ''}</div>
        <div>
          <IconGoTo height='16' className='transform rotate-180 stroke-current hover:bg-gray-100 rounded-full' />
        </div>
      </ListRow>
    })
  }
  return <React.Fragment>
    <div className='bg-white p-2 border-b border-t border-gray-100 grid grid-cols-3 gap-4'>
      <div className='text-gray-600 text-2l'>Trainings</div>
      <div></div>
      <div className='flex justify-end'>
        <OutlineButton color='primary' className='rounded-full' onClick={openModal}>
          <IconTrackAdd className='stroke-current' height='20' />
          Add Training
        </OutlineButton>
        <NewTrainingModal closeModal={closeModal} />
      </div>
    </div>
    <div className='bg-white p-2 text-sm'>
      {view}
    </div>
  </React.Fragment>
}