import { Collections } from 'api/firebase'
import * as React from 'react'
import { OrgMember } from 'api/models'
import { OrgMembersList } from './OrgMembersList'
import { OrgMembersInvitationModal } from './MembersInvitationModal'
import { ModalContext } from 'components/Modal'
import  * as api  from 'api'
import { useParams } from 'react-router'

interface P {
  className?: string
}

export const OrgMembers:React.FC<P> = ({ className }) => {
  const { org_id  } = useParams<{org_id:string}>()
  const [orgMembers, setOrgMember] = React.useState<OrgMember[]>([])
  const [isLoading, toggleIsLoading] = React.useState<boolean>(true)
  const { openModal } = React.useContext(ModalContext)

  React.useEffect(() => {
    api.subscribe(Collections.ORG_MEMBERS).where('org.id', '==', org_id as string).onData((data) => {
      setOrgMember(data as OrgMember[])
      toggleIsLoading(false)
    })
    
  }, [org_id])

  return <>
    <OrgMembersInvitationModal org_id={org_id} />
    <OrgMembersList
      orgMembers={orgMembers}
      className={className} 
      isLoading={isLoading}
      openModal={openModal}
    />
  </>
}