// import { Message, MessageType } from 'helpers/messages'
import { SplashScreen } from 'layouts/SplashScreen'
import * as React from 'react'
import { useParams } from 'react-router'
import { ProjectProps } from 'api/models'
import { ProjectDetail } from './ProjectDetails'
import { getProject } from 'api/projects'
import { getProjectMembership } from 'api/project-member'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthSelector } from 'auth/auth-reducer'
import { CHANGE_USER_ACTIVE_ORG, useUserOrgs } from 'orgs/orgs-reducers'

interface T {
  project_id: string
}

export const Project = () => {
  const [project, setProject] = React.useState<ProjectProps>({} as ProjectProps)
  const [isLoading, toggleIsLoading] = React.useState(true)

  const { data: user } = useSelector(useAuthSelector)
  const dispatch = useDispatch()
  const userOrgs = useSelector(useUserOrgs)

  const { project_id } = useParams<T>()

  React.useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const project = await getProject(project_id)
        const membership = await getProjectMembership(user.uid, project_id)
        if (project) {
          setProject(project)
        }
        if (membership) {
          
        }
        toggleIsLoading(false)
      } catch (e) {
        toggleIsLoading(false)
      }
    }
    fetchProjectDetails()
  }, [project_id, user.uid])

  React.useEffect(() => {
    const activeOrg = userOrgs.find(({ org }) => (org.id === project.org_id))
    dispatch({
      type: CHANGE_USER_ACTIVE_ORG,
      data: activeOrg
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, project.org_id])

  if (isLoading) {
    return <SplashScreen />
  }

  return <ProjectDetail project={project} />
}