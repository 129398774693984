import * as React from 'react'
import { LearningTrackProps } from '../model'
import { LearningTrackListToolbar } from '../LearningTracksToolbar'
import { Link } from 'react-router-dom'

const learningTracksRows = (track: LearningTrackProps) => {
  return <Link to={`/orgs/${track.org_id}/tracks/${track.id}`}>
    <div className='grid grid-cols-4 gap-4 text-gray-600 p-4 border-t border-gray-100' key={track.id}>
      <div>{track.name}</div>
      <div className='col-span-2'>{track.description}</div>
    </div>
  </Link>
}

interface T {
  tracks: LearningTrackProps[]
}

export const LearningTrackList:React.FC<T> = ({ tracks }) => {
  const rows = tracks.map(learningTracksRows)
  return <div className='bg-white text-sm py-8'>
    <LearningTrackListToolbar />
    {rows}
  </div>
}