import * as React from 'react'
import { Siftora } from '../Siftora'
import { Link } from 'react-router-dom'

export const LandingNavbar = ({ className }) => {
  //Todo: class override system
  let classes = [ ...('flex flex-wrap justify-between p-2 items-stretch content-center'.split(' ')), ...(className.split(' '))]
  return <>
    <div className={classes.join(' ')} >
      <div><Siftora className='text-2xl' color='white' /></div>
      <div className='hidden md:visible self-center'>
        <ul className="flex">
          <li className="px-4 mr-6 text-sm">
            <Link to='/'>Prices</Link>
          </li>
          <li className="mr-6 text-sm">
            <Link to='/'>Services</Link>
          </li>
          <li className="mr-6 text-sm">

          </li>
          <li className="mr-6 text-sm">
            <Link to='/' >Support</Link>
          </li>
        </ul>
      </div>
      <div className='text-white self-center'>
        <ul className="flex content-center">
          <li className="mr-6">
            <Link to='/login' className='text-sm'>Signin</Link>
          </li>
          <li>
            <Link to='/signup' className='text-sm'>Signup</Link>
          </li>
        </ul>
      </div>
    </div>
  </>
}