import { OutlineButton } from 'components/Buttons'
import { DropDown, DropDownItem } from 'components/DropDownMenu'
import { NameAvatar } from 'components/Avater/NameAvatar'
import * as React from 'react'
import { Siftora } from 'Siftora'
import { ReactComponent as IconMore } from 'assets/Icons/chevron.svg'
import { Logout } from 'components/Logout'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthSelector } from 'auth/auth-reducer'
import { CHANGE_USER_ACTIVE_ORG, useGetDefaultActiveOrg, useOrgs } from 'orgs/orgs-reducers'
import { useHistory } from 'react-router'
import { OrgMember } from 'api/models'

export const UserToolBar = () => {
  const { data: user } = useSelector(useAuthSelector)
  const orgs  = useSelector(useOrgs)
  const activeOrg = useSelector(useGetDefaultActiveOrg)
  const dispatch = useDispatch()
  const history = useHistory()

  const [orgDropdownIsOpen, toggleOrgDropDownIsOpen] = React.useState(false)
  const [profileDropdownIsOpen, toggleProfileDropdownIsOpen] = React.useState(false)

  const handleActiveOrgChange = React.useCallback((membership:OrgMember) => {
    if (membership) {
      dispatch({type: CHANGE_USER_ACTIVE_ORG, data: membership})
    }
    toggleOrgDropDownIsOpen(false)
    history.push(`/orgs/${membership.org.id}`)
  }, [dispatch, history])

  const orgsList = orgs.map(membership => 
    <DropDownItem onClick={() => handleActiveOrgChange(membership)} key={membership.org.id}>
      {membership.org.name}
      </DropDownItem>
  )

  return <div className='flex flex-col w-full justify-items-stretch'>
    <Siftora className='text-3xl mb-8' color='primary' />
    <div className='font-sans text-l subhead text-gray-800 flex flex-col divide-y divide-gray-100'>
      <OutlineButton onClick={() => toggleProfileDropdownIsOpen(true)} className='flex gap-2 items-center'>
        <NameAvatar name={user.name} />
        {user.name}
        <IconMore height='16' className='stroke-current' />
      </OutlineButton>
      <DropDown isOpen={profileDropdownIsOpen} onMouseLeave={() => toggleProfileDropdownIsOpen(false)}>
        <DropDownItem>
          <Logout />
        </DropDownItem>
      </DropDown>
    </div>
    <div className='font-sans text-gray-900 flex flex-col mb-4'>
      <OutlineButton 
        onClick={() => toggleOrgDropDownIsOpen(!orgDropdownIsOpen)} 
        className='items-center justify-between bg-gray-100 w-full'>
        {activeOrg.org.name} <IconMore height='16' className='stroke-current' />
      </OutlineButton>
      <DropDown isOpen={orgDropdownIsOpen} onMouseLeave={() => toggleOrgDropDownIsOpen(false)}>
        {orgsList}
      </DropDown>
    </div>
  </div>
}