import * as React from 'react'
import { ReactComponent as AddMentor } from 'assets/Icons/user-add.svg'
import { ReactComponent as AddCohortIcon } from 'assets/Icons/add.svg'
import { OutlineButton } from 'components/Buttons'
import { CohortSelect } from './CohortSelect'
import { useParams } from 'react-router'
import { fetchCohorts } from 'api/learning-tracks'
import { Cohort } from 'api/models'
import { Avatar } from 'components/Avater'



export const LearningTracksCohorts = ({onOpenModal, learners}) => {
  const [activeCohort, setActiveCohort] = React.useState<Cohort>()
  const [cohorts, setCohorts] = React.useState<Cohort[]>([])
  const { trackId } = useParams<{trackId: string}>()

  React.useEffect(() => {
    fetchCohorts(trackId, setCohorts)
  }, [trackId])

  React.useEffect(() => {
    if (!activeCohort) {
      setActiveCohort(cohorts[0])
    }
  }, [activeCohort, cohorts])

  const rows = learners
    .filter(member => member.cohort.id === activeCohort?.id)
    .map(learner => {
    return <div className='grid grid-cols-6 p-4  text-sm items-center' key={learner.id}>
      <Avatar user={learner.user} />
      <div className='col-span-5'>{learner.user.name}</div>
    </div>
  })

  return <React.Fragment>
    <div className='grid grid-cols-6 gap-2 justify-between border-b-2 border-gray-100 p-2 items-center text-sm'>
      <div className='col-span-2'>
        <CohortSelect activeCohort={activeCohort} onCohortChange={setActiveCohort} cohorts={cohorts} />
      </div>
      <div className='col-span-2'>
        <OutlineButton onClick={() => onOpenModal('Cohort')} color='primary'>
          <AddCohortIcon height='20'/> Add Cohort
        </OutlineButton>
      </div>
      <OutlineButton onClick={() => onOpenModal('Learner', { cohort: activeCohort })}  className='col-span-2 text-sm' color='secondary'>
        <AddMentor height='20'/> Add Learners
      </OutlineButton>
    </div>
    {rows}
  </React.Fragment>
}