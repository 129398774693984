import * as api from "api"
import { Collections } from "api/firebase"
import { TrainingProps, TrainingSessionsProps } from "./models"

export const saveTraining = (values:TrainingProps) => {
  return api.save(Collections.TRAININGS, {
    ...values,
    active: true
  })
}

export const fetchTrainings = (org_id:string, handler) => {
  return api.subscribe(Collections.TRAININGS)
  .where('org.id', '==', org_id)
  .onData((records) => {
    handler(records as TrainingProps[])
  })
}

export const getTraining = (training_id:string) => {
  return api.get(Collections.TRAININGS, training_id)
    .then(training => {
      return {...training} as TrainingProps
    })
}

export const saveTrainingSession = (trainingSession: Omit<TrainingSessionsProps, 'id'>) => {
  return api.save(Collections.TRAINING_SESSIONS, trainingSession)
}

export const fetchTrainingSessions = (trainingId:string, callback) => {
  return api.subscribe(Collections.TRAINING_SESSIONS)
    .where('training.id', '==', trainingId)
    .onData((docs) => {
        const sessions = docs.map(doc => {
        doc = api.convertFBTimeToDate('start_date', doc)
        return doc as TrainingSessionsProps
      })
      callback(sessions)
    })
}