import * as React from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'
import { Button } from 'components/Buttons'
import { InputText } from 'components/InputText'
import * as Storage from 'api/storage'
import { SupportedFileTypes } from 'api/models'

interface T {
  filepath: string
}

interface I {
  fileType?: string
}

const FileTypeSupport:React.FC<I> = ({ fileType }) => {
  return <div className='flex gap-2 p-2' >
    <span>Supported file types: </span>
    {Object.keys(SupportedFileTypes).map(ext => {
      return <span key={ext} className={cn({
        'text-green-500': SupportedFileTypes[ext] === fileType
      })}>{ext}</span>
    })}
  </div>
}

export const Studio:React.FC<T> = () => {
  const { org_id } = useParams<{org_id: string}>()
  const [files, setFiles] = React.useState<FileList | null>(null)
  const [fileTitle, setFileTitle] = React.useState('')
  const [supportedFileType, setSupportedFileType] = React.useState(false)
  const [fileSize, setFileSize] = React.useState<number>(0)
  const [canSubmit, setCanSubmit] = React.useState(false)

  const resetForm = () => {
    setFiles(null)
    setFileTitle('')
    setFileSize(0)
  }

  if (!org_id ) throw Error('Missing org id')

  const path = `${org_id}/${fileTitle}`
  const maxFileSize = 6000000

  React.useEffect(() => {
    if (!files) return
    setFileTitle(files[0].name)
    if (Object.values(SupportedFileTypes).includes(files[0].type as SupportedFileTypes)) {
      setSupportedFileType(true)
    }
    setFileSize(files[0].size)
  }, [files])

  React.useEffect(() => {
    const canSubmit = supportedFileType && (fileSize <= maxFileSize)
    setCanSubmit(canSubmit)
  }, [fileSize, supportedFileType])

  const handleFileUpload = React.useCallback(() => {
    if (!files) return 
    Storage.saveFile(files[0], path, () => 1, (fileUrl) => Storage.saveMediaMeta({
      title: fileTitle,
      type: files[0].type as SupportedFileTypes,
      url: fileUrl,
      org_id
    }))

    resetForm()
  }, [fileTitle, files, org_id, path])

  const fileType = files ? files[0].type : ''
  

  return <div className='grid grid-cols-3'>
    <div className='bg-white m-6 p-10 w-96 rounded-md'>
      <InputText type='file' onChange={(e) => setFiles(e.target.files)} className={cn()} />
      <InputText label='Title' value={fileTitle} onChange={(e) => setFileTitle(e.target.value)} />
      <FileTypeSupport fileType={fileType} />
      <div className={cn('p-4', {
        'text-red-600': fileSize > maxFileSize,
      })}>File size(max {maxFileSize / 1000000 }mb): {Math.round(fileSize / 1000000).toFixed(2)}mb</div>
      <Button color='primary' disabled={!canSubmit} onClick={handleFileUpload} className='m-4'>
        Upload to Library
      </Button>
    </div>
  </div>
}