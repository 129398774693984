import { Collections, fdb } from 'api/firebase'
import { ModalContext } from 'components/Modal'
import { BarSpinner } from 'components/Spinners'
import * as React from 'react'
import { useParams } from 'react-router'
import { ProjectMemberProps } from 'api/models'
import { ProjectMembersInvitationModal } from '../ProjectMembersInviteModal'
import { MembersList } from './MembersList'

interface T {
  project_id: string
}

export const ProjectMembers:React.FC<{}> = () => {
  const { project_id } = useParams<T>()
  const { openModal, closeModal } = React.useContext(ModalContext)

  const [isLoading, toggleIsLooad] = React.useState(true)
  const [members, setMembers] = React.useState<ProjectMemberProps[]>([])

  React.useEffect(() => {
    const unsubscribe = fdb.collection(Collections.PROJECT_MEMBERS)
      .where('project.id', '==', project_id)
      .onSnapshot((res) => {
          const members = res.docs.map(r => ({
            ...r.data(),
            uid: r.id
          } as ProjectMemberProps))

          setMembers(members)
          toggleIsLooad(false)
      })

      return () => unsubscribe()
  }, [project_id])
  if (isLoading) {
    return <BarSpinner />
  }
  return <React.Fragment>
    <ProjectMembersInvitationModal project_id={project_id} closeModal={closeModal} />
    <MembersList members={members} openModal={openModal} />
  </React.Fragment>
}