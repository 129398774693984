import { fetchLearningTracks } from 'api/learning-tracks'
import { OutlineButton } from 'components/Buttons'
import { DropDown, DropDownItem } from 'components/DropDownMenu'
import { LearningTrackProps } from 'LearningTracks/model'
import * as React from 'react'
import { useParams } from 'react-router'
import { ReactComponent as IconMore } from 'assets/Icons/chevron.svg'

interface T {
  onTrackChange(track: LearningTrackProps): void
}

export const LearningTrackSelect:React.FC<T> = ({ onTrackChange }) => {
  const { org_id } = useParams<{org_id: string}>()

  const [tracks, setTracks] = React.useState<LearningTrackProps[]>([])
  const [menuIsOpen, toggleMenuIsOpen] = React.useState(false)
  const [selectedTrack, setSelectedTrack] = React.useState({} as LearningTrackProps)

  React.useEffect(() => {
    fetchLearningTracks(org_id, (tracks) => {
      setTracks(tracks)
      setSelectedTrack(tracks[0])
    })
  }, [org_id])

  React.useEffect(() => {
    onTrackChange(selectedTrack)
  }, [onTrackChange, selectedTrack])

  const getMenus = React.useCallback(() => {
    return tracks.map(track => <DropDownItem key={track.id} onClick={() => setSelectedTrack(track)}>
      {track.name}
    </DropDownItem>)
  }, [tracks])

  return <React.Fragment>
    <OutlineButton onClick={() => toggleMenuIsOpen(!menuIsOpen)} className='items-center'>
      {selectedTrack && selectedTrack.name} <IconMore height='16' />
    </OutlineButton>
    <DropDown isOpen={menuIsOpen} onMouseLeave={() => toggleMenuIsOpen(false)}>
      {getMenus()}
    </DropDown>
  </React.Fragment>
}