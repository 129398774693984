import * as React from 'react'
import { ReactComponent as AddMentor } from 'assets/Icons/user-add.svg'
import { OutlineButton } from 'components/Buttons'
import { LearningTrackMember } from 'api/models'
import { Avatar } from 'components/Avater'
interface T {
  mentors: LearningTrackMember[],
  onOpenModal(): void
}

export const LearningTrackMentors:React.FC<T>= ({ mentors, onOpenModal }) => {
       
  const rows = mentors.map(mentor => {
    return <div className='grid grid-cols-6 p-4' key={mentor.id}>
      <Avatar user={mentor.user} />
      <div className='col-span-5'>{mentor.user.name}</div>
    </div>
  })
  return <div className='bg-white '>
    <div className='flex gap-2 justify-between border-b-2 border-gray-100 p-2'>
      <h3>Mentors</h3>
      <OutlineButton onClick={onOpenModal}><AddMentor height='20'/></OutlineButton>
    </div>
    <div>
      { rows }
    </div>
  </div>
}