import { User } from 'auth/auth-model'
import { Button } from 'components/Buttons'
import { MemberInvitation } from 'components/MemberInvitation'
import { MessageDisplay } from 'components/Message'
import { ModalBody } from 'components/Modal/ModalBody'
import { ModalFooter } from 'components/Modal/ModalFooter'
import { ModalHeader } from 'components/Modal/ModalHeader'
import { Select } from 'components/Select'
import { Message } from 'helpers/messages'
import * as React from 'react'
import { ReactComponent as IconSend } from 'assets/Icons/send.svg'

interface P {
  addRow(): void
  removeRow(id: string): void
  members: User[],
  onEmailChange(id: string, field: string, value: string): void
  onNameChange(id: string, field: string, value: string): void
  roles: { label: string, value: string }[]
  currentRole: string
  onRoleChange(role: string): void
  message?: Message
  onSendInvitations(): void
}
export const ProjectMembersInvitationForm: React.FC<P> = ({
  members,
  onEmailChange,
  addRow,
  removeRow,
  roles,
  currentRole,
  onRoleChange,
  message,
  onSendInvitations
}) => {
  const rows = members.map((user) => {
    return <MemberInvitation key={user.uid}
      email={user.email}
      name={user.name}
      onEmailChange={(e) => onEmailChange(user.uid, 'email', e.target.value)}
      onNameChange={(e) => onEmailChange(user.uid, 'name', e.target.value)}
      onAdd={addRow}
      onRemove={() => removeRow(user.uid)}
    />
  })

  return <div className='bg-white'>
    <ModalHeader title='Invite Members' />
    <ModalBody>
      {message && <MessageDisplay text={message.text} type={message.type}  />}
      <Select options={roles} label='Role' onChange={(e) => onRoleChange(e.target.value)} value={currentRole} />
    </ModalBody>
    {rows}
    <ModalFooter>
      <Button color='primary' onClick={onSendInvitations}>
        <IconSend className='stroke-current' height='20'  />
        Send Invitations
      </Button>
    </ModalFooter>
  </div>
}