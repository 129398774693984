import { hasMinLen, isValidEmail } from 'helpers/validators'
import * as React from 'react'
import { SignupForm } from './Signup'
import { Message, MessageType } from 'helpers/messages'
import { User } from 'auth/auth-model'
import { signUp } from 'api/auth'

interface D extends User {
  confirmPassword: string
  firstname: string
  surname: string
}

interface E {
  firstname: boolean
  surname: boolean
  email: boolean
  password: boolean
  confirmPassword: boolean
}

export const Signup: React.FC = () => {
  const initialState:D = {
    name: '',
    password: '',
    confirmPassword: '',
    firstname: '',
    surname: '',
    email: '',
    uid: ''
  }
  const [message, setMessage] = React.useState<Message>()
  
  const handleSignup = React.useCallback(({
    email,
    password,
    firstname,
    surname
  }:D) => {
    if (!(email && password)) {
      return
    }
    signUp(email, password, firstname, surname)
      .then(() => {
        setMessage({type: MessageType.success, text: 'Your account has been created successfully. Please check your email to verify'})
      })
      .catch(err => {
        setMessage({type: MessageType.error, text: err.message})
      })
  }, [])

  const validate = (values:D) => {
    const errors = {} as E
    if (!hasMinLen(values.firstname, 2)) {
      errors.firstname = true
    }
    if (!hasMinLen(values.surname, 2)) {
      errors.surname = true
    }
    if (!isValidEmail(values.email)) {
      errors.email = true
    }
    if (!hasMinLen(values.password, 6)) {
      errors.password = true
    }
    if (!(values.password === values.confirmPassword)) {
      errors.confirmPassword = true
    }
    return errors
  }

  return <>
    <SignupForm 
      initialValues={initialState} 
      onSubmit={handleSignup} 
      message={message}
      validate={validate}
    />
  </>
}
