import { Colors } from 'helpers/colors'
import * as React from 'react'

interface T {
  progress: number
  color?: Colors
}


export const ProgressBar:React.FC<T> = ({ progress, color = Colors.PRIMARY}) => {
  const actualProgress = progress <= 100 ? progress : 100

  return <div className={`shadow w-full bg-${color}-100`}>
    <div className={`bg-${color}-400 text-xs leading-none py-1 text-center text-white rounded-r-full`} style={{width: `${actualProgress}%`}}>{`${actualProgress}%`}</div>
  </div>
}