export enum MessageType {
  success = 'Success',
  warning = 'Warning',
  info = 'Info',
  error = 'Error'
}

export interface Message {
  type: MessageType
  text: string
}