import { Colors } from 'helpers/colors'
import * as React from 'react'
import Loader from 'react-loading' 

interface P {
  height?: number
  width?: number
  color?:Colors
}

export const BarSpinner:React.FC<P> = ({
  height = 20,
  width = 20,
  color
}) => {
  return <Loader type='bars' height={height} width={width} color={color} />
}

export const CircleSpinner:React.FC<P> = ({
  height = 20,
  width = 20
}) => {
  return <Loader type='spin' height={height} width={width} color='' />
}