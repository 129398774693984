import * as React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

export const MenuItem = ({ children, to, className = '' }) => {
  return <Link 
    to={to} 
    className={cn('flex gap-0 p-2 hover:bg-gray-400 text-sm', className)}>
    {children}
  </Link>
}

export const MenuItemIcon = ({ children, className = '' }) => {
  return <span className={cn('col-span-1 w-1/5', className)}>{children}</span>
}

export const MenuItemText = ({ children, className ='' }) => {
  return <span className={cn('col-span-2 w-4/5', className)}>{children}</span>
}
