import { DurationTypes, TrainingProps, TrainingSessionsProps } from 'api/models'
import { saveTrainingSession } from 'api/trainings'
import { Button } from 'components/Buttons'
import { FormField } from 'components/FormField'
import { InputText } from 'components/InputText'
import { RadioButton } from 'components/RadioButton'
import { CircleSpinner } from 'components/Spinners'
import { Colors } from 'helpers/colors'
import * as React from 'react'

interface T {
  training: TrainingProps
}

export const TrainingSessionForm:React.FC<T> = ({ training }) => {
  const [name, setName] = React.useState('')
  const [startDate, setStartDate] = React.useState('')
  const [duration, setDuration] = React.useState('')
  const [durationType, setDurationType] = React.useState('')

  const [loading, toggleLoading] = React.useState(false)

  const resetForm = React.useCallback(() => {
    setStartDate('')
    setName('')
    setDurationType('')
    setDuration('')
  }, [])

  const handleSaveSession = () => {
    toggleLoading(true)
    const newSession:Omit<TrainingSessionsProps, 'id'> = {
      name,
      start_date: new Date(startDate),
      duration: parseInt(duration, 10),
      training: {id: training.id, name: training.title},
      duration_type: DurationTypes[durationType]
    }
    saveTrainingSession(newSession)
      .then(resetForm)
      .finally(() => toggleLoading(false))
  }

  const durationOptions = Object.keys(DurationTypes).map(key => ({value: key, label: DurationTypes[key]}))
  
  const btnContent = loading ? <React.Fragment><CircleSpinner color={Colors.PINK}/> working</React.Fragment> : <span>Register Session</span>
  return <div className='bg-white'>
    <div className='border-b-2 border-gray-100 p-2'>Register New Session</div>
    <div className='p-4'>
      <FormField>
        <InputText 
          label='Session Name' 
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FormField>
      <FormField>
        <InputText 
          type='date' 
          label='Start Date' 
          onChange={(e) => setStartDate(e.target.value)}
          value={startDate} 
        />
      </FormField>
      <FormField>
        <InputText 
          type='number' 
          label='Duration' 
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
        />
      </FormField>
      <FormField className='border border-gray-100 rounded p-4'>
        <span>Duration type</span>
        <RadioButton options={durationOptions} selectedOption='' onSelect={(value) => setDurationType(value)} inline name='training_durations' />
      </FormField>
      <FormField className='flex justify-end'>
        <Button color={Colors.TEAL} onClick={handleSaveSession} disabled={loading}>
          { btnContent }
        </Button>
      </FormField>
    </div>
  </div>
}