import * as React from 'react'
import { Button } from 'components/Buttons'
import { ModalBody } from 'components/Modal/ModalBody'
import { ModalFooter } from 'components/Modal/ModalFooter'
import { ModalHeader } from 'components/Modal/ModalHeader'
import { FormField } from 'components/InputText'
import { useFormik } from 'formik'
import { Select, SelectOption } from 'components/Select'

export interface TrainingFormProps {
  title:string
  description: string,  
}

interface T {
  validate(D:TrainingFormProps): {title: boolean, description: boolean}
  initialValues: TrainingFormProps
  onSubmit(D:TrainingFormProps): void
  selectedLearningTrack: SelectOption
  learningTracks: SelectOption[]
  onSelectedLearningTrackChange(e:string): void
}

export const NewTrainingForm:React.FC<T> = ({ 
  validate, 
  initialValues, 
  onSubmit,
  selectedLearningTrack,
  learningTracks,
  onSelectedLearningTrackChange
 }) => {
  const formik = useFormik({
    validate,
    initialValues,
    onSubmit
  })

  const ok = (formik.dirty && !(formik.errors.title || formik.errors.description))
  return <React.Fragment>
    <form onSubmit={formik.handleSubmit}>
      <ModalHeader title='New Training' />
      <ModalBody>
        <Select 
          options={learningTracks} 
          onChange={(e) => onSelectedLearningTrackChange(e.target.value)}
          value={selectedLearningTrack.value}
         />
        <FormField
          name='title'
          hasError={formik.errors.title} 
          label='Name of Training'
          onChange={formik.handleChange}
        />
        <FormField
          name='description'
          hasError={formik.errors.description} 
          label='Description'
          onChange={formik.handleChange}
        />
      </ModalBody>
      <ModalFooter>
        <Button type='button' color='primary' raised disabled={!ok} onClick={() => onSubmit(formik.values)}>
          Submit
        </Button>
      </ModalFooter>
    </form>
  </React.Fragment>
}