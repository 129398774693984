import { OutlineButton } from 'components/Buttons'
import * as React from 'react'
import { useParams } from 'react-router'
import { ReactComponent as FileImport } from 'assets/Icons/file-import.svg'
import { Colors } from 'helpers/colors'
import { ModalContext } from 'components/Modal'
import { NewSlideModal } from './NewSlide/NewSlideModal'

export const SlideDeckEditor = () => {
  const { slide_decks: slideDeckID } = useParams<{slide_decks}>()
  const { openModal } = React.useContext(ModalContext)
  
  return <React.Fragment>
    <div className='bg-white p-4 border-1 border-gray-100 flex justify-between'>
      <div className=''></div>
      <div className=''>
        <OutlineButton color={Colors.SECONDARY} onClick={() => openModal()}>
          <FileImport height='20' className='stroke-current'/>
          New Slides
        </OutlineButton>
      </div>
    </div>
    <NewSlideModal />
  </React.Fragment>
}