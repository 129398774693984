import { get } from "api"
import { Collections } from "api/firebase"
import { ProjectProps } from "./models"

export const getProject = (id:string) => {
  return get(Collections.PROJECTS, id)
    .then(dd => {
      const data = dd
      if (!(data && data.id)) return null
      const project = {
        ...data
      } as ProjectProps
      return project
    })
}